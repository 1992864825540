import { transformImg } from '@shein/common-function'
import { daEventCenter } from 'public/src/services/eventCenter'

export default {
  namespaced: true,
  state: {},
  mutations: {},
  actions: {
    expose_lowest_days({ getters }) {
      let priceday = getters.common_info.priceInfo.lowestPrice?.days
      if (!priceday) return
      daEventCenter.triggerNotice({
        daId: '1-6-1-172',
        extraData: { 
          priceday,
        },
      })
    }
  },
  getters: {
    common_info(state, getters, rootState, rootGetters) {
      let fsAbt = rootGetters['productDetail/common/fs_abt']
      let priceInfo = rootGetters['productDetail/price_info']
      let skuInfo = rootGetters['productDetail/common/sku_info'] || {}
      let goodsId = rootGetters['productDetail/common/goods_id']
      let goodsSn = rootGetters['productDetail/common/goods_sn']
      let mallCode = rootGetters['productDetail/common/mall_code']
      let quickShip = rootGetters['productDetail/common/quick_ship']
      let language = rootGetters['productDetail/common/language_v2']
      let productRelationID = rootGetters['productDetail/common/product_relation_id']
      let promotionInfo = rootGetters['productDetail/promotion/promotion_info']
      let skcPromotionInfo = rootGetters['productDetail/promotion/skc_promotion_info']
      let complianceModeDe = rootGetters['productDetail/common/compliance_mode_de']
      return {
        fsAbt,
        priceInfo,
        skuInfo,
        goodsId,
        goodsSn,
        mallCode,
        quickShip,
        productRelationID,
        promotionInfo,
        complianceModeDe,
        language,
        skcPromotionInfo
      }
    },
    lowest_price_tips(state, getters, rootState, rootGetters) {
      // 保留款 + 最低价 + 税
      let { goodsId, goodsSn, productRelationID, priceInfo, quickShip, mallCode } = getters.common_info || {}
      let analyzeData = {
        location: 'detail',
        goods_list: `${goodsId}\`${goodsSn}\`${productRelationID}\`1\`1\`1\`\`\`pri_${priceInfo?.salePrice?.usdAmount}|pri_${priceInfo?.retailPrice?.usdAmount}\`${quickShip == 1 ? 'quickship_1' : ''}\`\`mall_${mallCode}\``
      }

      /* 保留款优先 */
      let bestDealText = rootState.productDetail?.hotModules?.beltInfo?.sameLabelBelt?.lowestPriceTips || ''
      let isSoldOut = rootGetters['productDetail/common/is_sold_out'] || rootGetters['productDetail/common/mall_stock'] == 0
      if (!!bestDealText && !isSoldOut) {
        analyzeData.pricestatus = 2
        return {
          text: bestDealText,
          mainPriceDiscountStyle: true,
          analyzeData,
        }
      }
      /* 保留款优先 */

      /* 最低价 */
      let lowestPriceTips = priceInfo?.lowestPrice?.tips || ''

      if (!lowestPriceTips) return null

      return {
        text: lowestPriceTips,
        mainPriceDiscountStyle: !!lowestPriceTips,
        analyzeData,
      }
    },
    after_pay_info(state, getters, rootState, rootGetters) {
      let { priceInfo, skuInfo } = getters.common_info || {}
      let { multiPaymentShowList } = priceInfo || {}
      let salePriceAmount = skuInfo?.salePrice?.amount || null
      let { language } = getters.common_info || {}
      if (!multiPaymentShowList || !multiPaymentShowList?.length) return null
      let list = []
      multiPaymentShowList.forEach(item => {
        let { show_desc_batch, show_desc, logo_url, jump_url, payment_code } = item
        let text = show_desc_batch?.find?.(i => i?.amount == salePriceAmount)?.desc || show_desc
        if (text) {
          list.push({
            text,
            logoUrl: transformImg({img: logo_url}),
            jumpUrl: jump_url,
            paymentCode: payment_code
          })
        }
      })
      if (!list.length) return null
      let firstItem = list[0]
      return {
        content: {
          ...firstItem
        },
        list,
        drawerTitle: language.SHEIN_KEY_PWA_17553
      }
    },
    app_exclusive_discount(state, getters, rootState, rootGetters) {
      let { skcPromotionInfo, language } = getters.common_info || {}
      let promoInfo = skcPromotionInfo?.find?.(item => item.typeId == 8) || null
      if (!promoInfo) return null
      return {
        data: promoInfo,
        language
      }
    },
  }
}
