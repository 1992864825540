import { template } from '@shein/common-function'
import schttp from 'public/src/services/schttp'
import qs from 'qs'
import { getQueryString, parseQueryString } from '@shein/common-function'
import { addCart, assignPreSearchSimilarAnalysis } from 'public/src/pages/goods_detail_v2/innerComponents/top/AddToCartBar/utils.js'
import { abtUserAnalysis } from '@shein-aidc/basis-abt-router'
import DETAIL_API_LOCATION from 'public/src/pages/product_app/store/modules/product_detail/api.js'
import getCoupon from 'public/src/pages/product_app/store/modules/product_detail/utils/getCoupon.js'
import { ESTIMATED_DATA_TYPES, getMainGoodsDiscountPercent } from 'public/src/pages/product_app/store/modules/product_detail/utils/price.js'
import { buyNow } from 'public/src/pages/product_app/store/modules/product_detail/utils/buyNow.js'
import pageContextCallBack from 'public/src/pages/goods_detail_v2/utils/pageContextCallBack.js'
import { quickAdd } from 'public/src/pages/components/product/quick_add_v2/index'
import { daEventCenter } from 'public/src/services/eventCenter'
import { getQuickAddCartInstance, SCENE_MAP, plugins, canUseNewQuickAdd } from 'public/src/pages/components/product/quick_add_v3/common.js'
import { BENEFIT_BENEFI_MAP, OPEN_QUICK_ADD_TYPE } from './constant' 
import salePriceWordSize from 'public/src/pages/goods_detail_v2/utils/sale-price-word-size.js'





export default {
  namespaced: true,
  state: {
    quick_add_state: false,
    nowTime: null
  },
  mutations: {
    update_quick_add_state(state, payload) {
      state.quick_add_state = payload
    },
    update_now_time(state, payload) {
      state.nowTime = payload
    },
  },
  actions: {
    async wish_goods({}, { data, isRemove = false }) {
      const url = isRemove ? DETAIL_API_LOCATION.GOODS_WHISHLIST_REMOVE : DETAIL_API_LOCATION.GOODS_WHISHLIST_ADD
      const result = await schttp({
        url,
        method: 'POST',
        headers: { 
          'Content-Type': 'application/x-www-form-urlencoded'
        },
        data: qs.stringify(data),
        useBffApi: true,
      })
      return result
    },
    async update_cart_free_info({ commit, rootGetters }) {
    },
    update_add_to_bag_info({ commit, rootGetters }, payload = {}) {
      try {
        window.vBus && window.vBus.$emit('closeLowStockBanner')
        const mainPriceInfo = rootGetters['productDetail/price/main_price_info']
        const discountInfo = rootGetters['productDetail/price/discount_info']
        const estimatedInfo = rootGetters['productDetail/price/estimated_info']
        const suggestedSalePriceInfo = rootGetters['productDetail/price/suggested_sale_price_info']
  
        const complianceTipsMode = rootGetters['productDetail/common/compliance_tips_mode']
        const complianceModeDe = rootGetters['productDetail/common/compliance_mode_de']
        const isHitComplianceMode = rootGetters['productDetail/common/is_hit_compliance_mode']
        let priceValue = ''
        let priceShowStyle = ''

        // 优先取estimatedInfo的到手价、然后取mainPriceInfo的价格
        const { SATISFY, AB, AB_OVERLAP_SATISFY, AB_OVERLAP_NO_SATISFY } = ESTIMATED_DATA_TYPES
        if (estimatedInfo?.value && [SATISFY, AB, AB_OVERLAP_SATISFY, AB_OVERLAP_NO_SATISFY].includes(estimatedInfo?.dataType)){
          priceValue = estimatedInfo.value?.amountWithSymbol || ''
          priceShowStyle = estimatedInfo.value?.priceShowStyle || ''
        }else {
          priceValue = mainPriceInfo?.value || ''
          priceShowStyle = mainPriceInfo.priceShowStyle || ''
        }
        let priceHtml = salePriceWordSize({ priceStr: priceValue, priceShowStyle, mainSize: 17, otherSize: 11 })

        const { lang_rp, GB_cssRight_rp } = rootGetters['productDetail/common/common_info'] || {}
        let discountPercent = getMainGoodsDiscountPercent({ discountInfo, estimatedInfo, suggestedSalePriceInfo, isHitComplianceMode, complianceTipsMode, complianceModeDe, lang_rp })

        if(GB_cssRight_rp){
          // 反语反转折扣率
          discountPercent = discountPercent?.replace(/(-)?(\d+(\.\d+)?)(%)/, '$4$2$1')
        }

        const addToBagMainGoods = {
          skuCode: payload?.sku_code || '',
          priceHtml,
          priceValue,
          priceColor: mainPriceInfo?.color,
          discountPercent,
          mainPriceInfo,
          discountInfo,
          estimatedInfo,
          addCartInfo: payload
        }

        commit('newProductDetail/common/updateAddToBagMainGoods', addToBagMainGoods, { root: true })          
      }catch(error) {
        console.error('update_add_to_bag_info', error)
      }
    },
    show_outfit_content({ commit, rootGetters }) {
      const add_bag_recommend_config = rootGetters['productDetail/Recommend/add_bag_recommend_config']
      const { addbagOutfitAlone, addbagOutfitInYmal } = add_bag_recommend_config || {}
      const outfits_info = rootGetters['productDetail/MainPicture/outfits_info']
      const isHasOutfits = outfits_info?.isHasOutfits && !!outfits_info?.gtlInfo
      if(isHasOutfits){
        if(addbagOutfitInYmal){
          commit('productDetail/MainPicture/update_show_outfit_ymal', true, { root: true })
          console.log('===> 展示YMAL插坑outfit卡片')
        }else if(addbagOutfitAlone){
          console.log('===> 展示YMAL上方插入outfitd模块')
          commit('productDetail/Recommend/update_show_outfit_alone', true, { root: true })
        }
      }
    },
    async get_add_cart_params({ rootGetters, rootState }, { isFromPromotion = false } = {}) {
      // 获取主商品加车参数
      const goods_id = rootGetters['productDetail/common/goods_id']
      const skc_sale_attr = rootGetters['productDetail/common/skc_sale_attr']
      const sku_list = rootGetters['productDetail/common/sku_list']
      const sku_info = rootGetters['productDetail/common/sku_info']
      const mall_code = rootGetters['productDetail/common/mall_code']
      const is_satisfied_buy_multiple = rootGetters['productDetail/common/is_satisfied_buy_multiple']
      const estimated_info = rootGetters['productDetail/price/estimated_info'] || {}
      const { addToBagForm } = rootState.newProductDetail?.common || {}
      const hasAttrs = skc_sale_attr?.length
      const sku_code = hasAttrs ? (sku_info?.sku_code || '') : (sku_list?.[0]?.sku_code || '')
      let quantity = addToBagForm?.quantity || 1
      if (isFromPromotion && is_satisfied_buy_multiple) {
        quantity = estimated_info?.purchasePcs
      }
      const result = {
        goods_id,
        quantity,
        mall_code,
        promotion_id: '0',
        promotion_type: '0',
        sku_code,
      }
      const addSource = getQueryString({ key: 'add_source' }) || ''
      if (addSource) {
        result.addSource = `detail_${addSource}`
      }
      const trace_id = typeof gbExposeTraceid !== 'undefined' ? gbExposeTraceid('getProduct', {
        goods_id,
      }) : ''
      if (trace_id) {
        result.trace_id = trace_id
      }
      return result
    },
    async add_to_bag({ commit, dispatch, rootState, rootGetters }, { 
      interestpoints,
      target,
      isReview = false,
      btnType = 'add_to_bag',
      scrollToRecommend = true,
      reportPostData = {},
      reportClickData = {},
      isFromPromotion = false,
      isFromBigImage = false,
      isFromBottomBanner = false,
    } = {}) {
      const language_v2 = rootGetters['productDetail/common/language_v2']
      const goodsReady = rootState.newProductDetail?.common?.goodsReady
      const estimated_info = rootGetters['productDetail/price/estimated_info'] || {}
      if (!goodsReady) return
      const get_add_cart_params = await dispatch('get_add_cart_params', { isFromPromotion })
      if (!get_add_cart_params?.sku_code) {
        if (isReview) {
          dispatch('open_quick_add_main_product', { isReview: true })
        } else if (isFromPromotion) {
          let screen = estimated_info?.dataType === ESTIMATED_DATA_TYPES.N_ESTIMATED ? OPEN_QUICK_ADD_TYPE.BUY_MULTIPLE : OPEN_QUICK_ADD_TYPE.NORMAL
          dispatch('open_quick_add_main_product', { isFromPromotion: true, screen })
        } else if (isFromBottomBanner) {
          dispatch('open_quick_add_main_product', { isFromBottomBanner: true })
        } else {
          dispatch('open_quick_add_main_product')
        }
        let extraData = {
          interestpoints,
          isLiseEmpty: true, // 不传递数据
          review_location: isReview ? 'page_review' : '',
          button_type: btnType,
          ...(reportClickData || {})
        }
        extraData = assignPreSearchSimilarAnalysis(extraData)
        // 低库存banner的加车埋点
        if (isFromBottomBanner) {
          extraData.location = 'page_bottomBanner'
        }
        // 【埋点统一】触发埋点
        daEventCenter.triggerNotice({
          daId: '2-3-5',
          target, // Dom元素
          extraData
        })
        return
      }
      const extra_params = {}
      if (rootGetters['productDetail/common/is_customization']) {
        const isCustomizationNewLink = rootGetters['productDetail/common/is_customization_new_link'] || false
        const customerMade = await import('public/src/pages/goods_detail/components/drawer/innerComponents/CustomeowDrawer/customerMade.js')
        const customization_info = await customerMade?.default?.({
          sku: get_add_cart_params?.sku_code,
          isNewLink: isCustomizationNewLink,
          errorTip: language_v2?.SHEIN_KEY_PWA_26887
        })
        if (!customization_info) return
        extra_params.customization_info = customization_info
      }
      appEventCenter.$emit('allLazyComponentRender')
      const goods_name_labels = rootGetters['productDetail/GoodsName/goods_name_labels']
      const newLabels = goods_name_labels?.find(label => label?.key === 'new') || {}
      const isNew = !!newLabels?.value
      const base_info = rootGetters['productDetail/common/base_info']
      const store_info = rootGetters['productDetail/common/store_info']
      const { quickShip, addToBagForm } = rootState.newProductDetail?.common || {}
      const default_mall_code = rootGetters['productDetail/common/default_mall_code']
      const trends_info = rootGetters['productDetail/Description/trends_info']
      const sku_info = rootGetters['productDetail/common/sku_info']
      const skc_sale_attr = rootGetters['productDetail/common/skc_sale_attr'] || []
      const attrList = rootState.productDetail.coldModules?.saleAttr.multiLevelSaleAttribute
      let variant = ''
      const attrs = []
      sku_info?.sku_sale_attr?.forEach?.(item => {
        if (item.attr_id == 87) {
          variant = item.attr_value_name
        }
        attrs.push({
          attr_id: item.attr_id,
          attr_value_id: item.attr_value_id,
        })
      })
      const extraParams = {
        interestpoints,
        review_location: isReview ? 'page_review' : '',
        isNew,
        button_type: btnType,
      }
      if (trends_info?.trend_word_id) {
        extraParams.passParams = {
          trend_tag: `${trends_info.trend_word_id}-${trends_info.product_select_id}`
        }
      }
      const extraSaParams = {
        quickShip,
        variant,
        is_default_mall: get_add_cart_params?.mall_code == default_mall_code ? 1 : 0,
        // TODO malltag怎么取 埋点没用了
        mallTag: '',
        attrs,
        ...(reportPostData || {}),
      }
      if (estimated_info?.dataType) {
        extraSaParams.threshold = estimated_info.dataType
      }
      // 低库存banner的加车埋点
      if (isFromPromotion) {
        extraSaParams.location = 'page_promotion'
      }
      // 低库存banner的加车埋点
      if (isFromBottomBanner) {
        extraSaParams.location = 'page_bottomBanner'
      }
      const reqParams = { ...get_add_cart_params, ...extra_params }
      const errorInfo = JSON.stringify({
        url: DETAIL_API_LOCATION.GOODS_ADD_TO_CART,
        goodsInfo: base_info,
        attrList,
        requestParams: reqParams,
        saleAttrList: skc_sale_attr,
        skuInfo: sku_info,
        hasAttrs: skc_sale_attr?.length,
      })
      const data = await addCart(reqParams, { 
        language: language_v2,
        target,
        extraParams,
        extraSaParams,
        base_info,
        store_info,
        errorInfo,
        closeSuccessPop: true,
        isFromBigImage,
      })
      // 加车后推荐 时机二 加车之后请求加车交易中间层数据 showAddBagRecDrawerSceneTwo
      const { showAddBagRecDrawerSceneTwo } = rootGetters['productDetail/Recommend/show_add_bag_rec_drawer_scene']
      if (showAddBagRecDrawerSceneTwo) {
        await dispatch('productDetail/Recommend/fetch_rec_data_actions', undefined, { root: true })
      }
      // --end
      if (isFromPromotion) {
        // TODO @seb
        window?.vBus?.$emit('addToBagSuccess', { data: data?.result, delayToast: 0 })
      }else {
        const isSuccess = data?.result?.code == 0
        dispatch('auto_get_coupon_main_product', { 
          needSuccessToast: isSuccess,
          needFailToast: !isSuccess,
          successToastDelay: 0,
        })
        // dispatch('autoGetCouponAtAddBag', { needToast: data?.result?.code == 0, delayToast: 0 })
      }
      if (data?.result?.code == 0) {
        commit('newProductDetail/common/updateAddCompleteStatus', true, { root: true })
        commit('newProductDetail/common/updateAddSkcCompleteStatus', true, { root: true })
        // 加车成功后存储当前商品的主要信息
        dispatch('update_add_to_bag_info', reqParams)
        dispatch('show_outfit_content')
        // --end
        appEventCenter?.$emit('recommend-refresh-data') // 加车成功后刷新底部推荐
        isFromPromotion && commit('newProductDetail/PromotionEnter/updateViewPromotionAddBag', Number(rootState.newProductDetail?.PromotionEnter?.viewPromotionAddBag) + 1)
        const initExposeStatus = rootState.newProductDetail.common?.initExposeStatus
        dispatch(
          'newProductDetail/common/updateExposeStatus',
          {
            addBagStatus: 1,
            addWishStatus: initExposeStatus?.addWishStatus || 0
          },
          {
            root: true,
          }
        )
        if (data.result?.info?.quantity) {
          commit('changeCartInfo', { sum: data.result.info.quantity }, { root: true })
        }
        window.vBus && window.vBus.$emit('triggerAddCompletedAnimation')
        let loadAddBagRecDrawerStatus = false
        // TODO keywang已融合, 加车后推荐
        if (showAddBagRecDrawerSceneTwo) {
          loadAddBagRecDrawerStatus = await dispatch('productDetail/Recommend/open_add_bag_recommend_drawer', { isDirect: true, isReview }, { root: true })
        }
        data.loadAddBagRecDrawerStatus = loadAddBagRecDrawerStatus
        const timer = setTimeout(async () => {
          clearTimeout(timer)
          const classHook = isReview ? '.j-header-bag-is-review' : '.j-header-bag'
          const $headerBag = document.querySelector(classHook)
          $headerBag?.classList?.add('bounce')
          // TODO 点击商详加车状态和汇总当天加车次数，后续CartEntry融合迁移
          commit('newProductDetail/BagData/updateIsAddCartInDetail', true, { root: true })
          commit('newProductDetail/BagData/updateAddBagCountOnTheDay', false, { root: true })
          // ---end
          if (!isReview && !loadAddBagRecDrawerStatus && scrollToRecommend) {
            dispatch('productDetail/Recommend/set_goods_detail_scroll', true, { root: true })
          }
          if(isReview) { // 弹窗弹窗加车成功出来推荐弹窗，关闭评论后，需要滚动到推荐位
            commit('productDetail/CommentPopup/update_close_comment_need_scroll_recomend', true, { root: true })
          }
          window?._gb_cart_tag_tips_?.update({
            excludeScroll: !isReview && scrollToRecommend
          })
          const { gift_selected_list, gift_selected_status } = rootState.productDetail.gift || {}
          if(gift_selected_list?.length && gift_selected_status) {
            // 包材料商品批量加车
            dispatch('productDetail/gift/batch_gift_add_to_bag', { gift_selected_list }, { root: true })
          }
          // 更新购物车利益点
          dispatch('fetchCartInfo', undefined, { root: true }).catch(() => null) 
        }, 500)
      }
      // TODO addToBagFail 事件没看到了，先删
      // window.vBus && window.vBus.$emit('addToBagFail', params)
      // 加车超出数量重置为1
      if (data?.msgInfo?.isOverLimit) {
        commit('newProductDetail/common/updateAddToBagForm', {
          ...addToBagForm,
          quantity: 1,
        }, { root: true })
      }
      if (location.hash && !isReview) {
        history.back()
      }
      return data
    },
    // 当加车商品为主商品，自动领券可以调
    async auto_get_coupon_main_product({ rootGetters, dispatch }, {
      needSuccessToast,
      needFailToast,
      successToastDelay
    }) {
      let mallCode = rootGetters['productDetail/common/mall_code'] || ''
      let goodsId = rootGetters['productDetail/common/goods_id'] || ''
      let language = rootGetters['productDetail/common/language_v2'] || ''
      let allCoupon = rootGetters['productDetail/coupon/cmp_coupon_info_list'] || []
      let estimatedInfo = rootGetters['productDetail/price/estimated_info']
      let couponCodes = []
      for(let i of allCoupon) {
        let { couponStatus, businessExtension, coupon } = i || {}
        let { isBindingCoupon, isFreeShipping, isFreeShippingThreshold, isOptimalCoupon } = businessExtension?.productDetail || {}
        // couponStatus 1-已经领取，2-未领取
        if (couponStatus == 2 && isBindingCoupon == 1) {
          if ((isFreeShipping && isFreeShippingThreshold)) {
            couponCodes.push(coupon)
          }
          if (!!estimatedInfo && isOptimalCoupon) {
            couponCodes.push(coupon)
          }
        }
      }

      if (!couponCodes.length) return

      let isSuccess = await getCoupon({
        couponCodes,
        needSuccessToast,
        language,
        needFailToast,
        successToastDelay
      })

      if (isSuccess) {
        dispatch('newProductDetail/asyncHotModles', {
          goods_id: goodsId,
          mallCode,
        }, { root: true })
      }

      daEventCenter.triggerNotice({
        daId: '1-6-4-42',
        extraData: {
          coupon_id: couponCodes.join(`,`),
          status: isSuccess ? 1 : 0,
          abtest: abtUserAnalysis({ posKeys: 'EstimatedPrice' })?.sa
        }
      })
    },
    async click_buy_now_btn({ rootState, rootGetters, dispatch }) {
      let goods_id = rootGetters['productDetail/common/goods_id']
      let skuInfo = rootGetters['productDetail/common/sku_info']
      let sku_code = skuInfo?.sku_code || ''
      if (!sku_code) {
        daEventCenter.triggerNotice({
          daId: '1-6-1-191',
          extraData: {
            goods_id
          }
        })
        return dispatch('open_quick_add_main_product', {
          screen: OPEN_QUICK_ADD_TYPE.BUY_NOW
        })
      }
      let goods_sn = rootGetters['productDetail/common/goods_sn']
      let { finalPrice } = rootGetters['productDetail/price/other_price_info'] || {}
      let fsAbt = rootGetters['productDetail/common/fs_abt'] || {}
      let { DetailBuynow, DetailPageQuickShipShow } = fsAbt || {}
      const { addToBagForm, quickShip } = rootState.newProductDetail?.common || {}
      let afterbuyroad = DetailBuynow?.p?.afterbuyroad === 'recommend'
      let quickship_tp = (() => {
        if (!!quickShip) {
          return DetailPageQuickShipShow?.p == 'DetailShowQuick' ? 1 : 0
        }
        return 3
      })()
      buyNow({
        language: rootGetters['newProductDetail/common/language'],
        httpSuccessCallBack: async () => await dispatch('auto_get_coupon_main_product', { needSuccessToast: true }),
        needJump: true,
        mall_code: String(rootGetters['newProductDetail/common/mallCode']),
        quantity: addToBagForm.quantity,
        sku_code,
        checkout_total: finalPrice.amount,
        buyNow: afterbuyroad ? 'afterbuyroad' : '',
        daData: {
          goods_id,
          quickship_tp,
          sku_id: goods_sn,
          location: 'page',
          is_buynow: '1',
          nobuynow_reason: ''
        }
      })
    },
    // 商详主商品打开快车
    open_quick_add_main_product({
      rootState,
      rootGetters,
      commit,
      dispatch
    }, param) {
      let { screen, isReview, isFromPromotion, isFromBottomBanner } = param || {}
      screen = screen || OPEN_QUICK_ADD_TYPE.NORMAL
      isReview = isReview || false
      isFromPromotion = isFromPromotion || false
      isFromBottomBanner = isFromBottomBanner || false
      let estimatedInfo = rootGetters['productDetail/price/estimated_info']
      let goods_id = rootGetters['productDetail/common/goods_id']
      let cat_id = rootGetters['productDetail/common/cat_id']
      let mallCode = rootGetters['productDetail/common/mall_code'] || ''
      let fixedRatio = rootGetters['productDetail/common/fixed_ratio']
      let trends_info = rootGetters['productDetail/Description/trends_info']
      let show_multi_layer = rootGetters['productDetail/common/show_multi_layer']
      let show_sale_attr_fold_entry = rootGetters['productDetail/SizeAttrFold/show_sale_attr_fold_entry']
      let rule_id = rootGetters['productDetail/SizeGuide/rule_id']
      let rule_type = rootGetters['productDetail/SizeGuide/rule_type']
      let closeEstimatedAndAbPrice = rootGetters['productDetail/common/close_estimated_and_ab_price']
      let quick_ship_content = rootGetters['productDetail/ShippingEnter/quick_ship_content']
      let fsAbt = rootGetters['productDetail/common/fs_abt'] || {}
      let language = rootGetters['productDetail/common/language_v2']

      let recommendMySize = rootState.newProductDetail?.common?.currentMySize
      let recommendMyBraSize = rootState.newProductDetail?.common?.currentMyBraSize
      let globalPerfectFitShow = rootState.newProductDetail?.SizeBox?.globalPerfectFitShow
      let forbidAtmosphereFlow = rootGetters['productDetail/common/forbid_atmosphere_flow']
      let externalSizeInfoIndex = rootState.newProductDetail.common.externalSizeInfoIndex
      let isAddWishAfterRec = false
      let showAddBagRecDrawerScene = rootGetters['productDetail/Recommend/show_add_bag_rec_drawer_scene']
      let { addToBagForm } = rootState.newProductDetail?.common
      let initExposeStatus = rootState.newProductDetail.common?.initExposeStatus
      let {
        gift_list,
        gift_selected_status,
        gift_selected_list,
        gift_lowest_price,
        gift_selected_prices
      } = rootState.productDetail.gift || {}
      let giftInfo = {
        giftList: gift_list || [],
        giftSelectedStatus: gift_selected_status || false,
        giftSelectedList: gift_selected_list || [],
        giftLowestPrice: gift_lowest_price || '',
        giftSelectedPrices: gift_selected_prices || '',
        productItemsLanguage: language || {},
      }
      const selectGiftList = ({ selectedStatus, selectedProducts }) => {
        commit('productDetail/gift/update_gift_selected_list', {
          gift_selected_status: selectedStatus,
          gift_selected_list: selectedProducts,
        }, { root: true })
        giftInfo.giftSelectedStatus = selectedStatus
        giftInfo.giftSelectedList = selectedProducts
      }
      const updateGiftInfo = ({ giftList, lowestPrice, giftSelectedPrices }) =>{
        if (giftList !== undefined) {
          giftInfo.giftList = giftList
          commit('productDetail/gift/update_gift_list', giftList || [], { root: true })
        }
        if (lowestPrice !== undefined) {
          giftInfo.giftLowestPrice = lowestPrice
          commit('productDetail/gift/update_gift_lowest_price', lowestPrice || '', { root: true })
        }
        if (giftSelectedPrices !== undefined) {
          giftInfo.giftSelectedPrices = giftSelectedPrices
          commit('productDetail/gift/update_gift_selected_prices', giftSelectedPrices || '', { root: true })
        }
      }
      let quantity = addToBagForm?.quantity || 1
      let cccSupportLargeImageList = Number(rootGetters['productDetail/common/main_sale_attribute']) === 1

      let { BUY_NOW, ATTRS_FOLD, BUY_MULTIPLE, LARGE_IMAGE_SWITCH } = OPEN_QUICK_ADD_TYPE
      let isAttrFold = screen === ATTRS_FOLD ? '1' : '0'
      let isBuyNow = screen === BUY_NOW
      let buyMultipleQuantity = screen === BUY_MULTIPLE ? estimatedInfo?.purchasePcs : 0
      let quickAddLargeImgMode = screen === LARGE_IMAGE_SWITCH

      appEventCenter.$emit('allLazyComponentRender')
      let cacheScroll = window.scrollY
      // 打开快加车窗口

      const changeSizeAttrs = ({ attrs, skuInfo, externalSizeInfoIndex, curSelectAttr, isAttrFold }) => {
        // 处理闭包
        const addToBagFormReal = rootState.newProductDetail?.common?.addToBagForm
        addToBagFormReal.attrs = attrs
        if (window.vBus) {
          window.vBus.$emit('changeWakeupData', {
            sku_code: skuInfo?.sku_code || '',
            selected_attr_value_list: attrs.map((_) => String(_.attr_value_id)) || [],
          })
        }
        commit('newProductDetail/common/updateSkuInfo', skuInfo, { root: true })
        commit('newProductDetail/common/updateAddToBagForm', addToBagFormReal, { root: true })
        commit('newProductDetail/common/updateExternalSizeInfoIndex', externalSizeInfoIndex, { root: true })
        if (curSelectAttr.attr_value_name) {
          daEventCenter.triggerNotice({
            daId: '1-6-1-33',
            target: {
              dataset: {
                attr_id: curSelectAttr.attr_id,
                attr_value_id: curSelectAttr.attr_value_id || '',
                attr_value: curSelectAttr.attr_value_name,
                sku_code: skuInfo?.sku_code,
                review_location: isReview ? 'page_review' : '',
                location: 'popup',
                isAttrFold,
              },
            },
          })
        }
      }
      const setBounce = (status) => {
        const classHook = '.j-header-bag-is-review'
        const $headerBag = document.querySelector(classHook)
        $headerBag?.classList?.[status ? 'add' : 'remove']('bounce')
      }

      let curExtraBusesData = null
      let curExtraMallData = null

      let onCloseDoSuccessCallBack = null
      let addSuccessCallBack = () => {
        let assignData = Object.assign({}, { cat_id, goods_id }, curExtraBusesData)
        appEventCenter.$emit('recommend-refresh-data', { cat_id: assignData.cat_id, goods_id: assignData.cat_id }) // 刷新促销数据
        if (curExtraBusesData) {
          commit('newProductDetail/common/updateAsyncScrollFromReview', false, { root: true })
          commit('newProductDetail/common/updateAsyncScrollStatus', true, { root: true })
        } else {
          dispatch('productDetail/Recommend/set_goods_detail_scroll', {}, { root: true })
        }
      }

      let clickCallBack = {
        isActive: (isShow) => {
          commit('update_quick_add_state', !!isShow)
          commit('newProductDetail/common/updateQuickAddState', !!isShow, { root: true })
          if (isShow) return
          onCloseDoSuccessCallBack?.()
          if (curExtraMallData) {
            const { mallCode } = curExtraMallData
            commit('newProductDetail/SizeBox/updateSwitchMallCode', mallCode, { root: true })
          }
          let { giftSelectedStatus, giftSelectedList, giftSelectedPrices } = giftInfo
          commit('productDetail/gift/update_gift_selected_list', {
            gift_selected_status: giftSelectedStatus,
            gift_selected_list: giftSelectedList,
          }, { root: true })
          commit('productDetail/gift/update_gift_selected_prices', giftSelectedPrices, { root: true })
          if (JSON.stringify(curExtraBusesData) !== '{}') {
            // 色块切换
            appEventCenter.$emit('close-popover-add-bag', curExtraBusesData)
          }
          dispatch('productDetail/SizeBox/update_local_unit', null, { root: true })
          // 立即购弹窗返回商详页，下滑至推荐位
          const afterbuyroadpop = fsAbt?.DetailBuynow?.p?.afterbuyroadpop === 'recommend'
          if(isBuyNow && afterbuyroadpop) {
            dispatch('productDetail/Recommend/set_goods_detail_scroll', {}, { root: true })
          }
        }, // 快加车弹窗状态
        selectSizeDefault: (params) => {
          // 非用户选择的尺寸默认选中
          changeSizeAttrs(params)
        },
        // 尺寸选择
        selectSize: (params) => {
          changeSizeAttrs(params)
          // 在加车弹窗里切换了skc，需要延迟更新一下w
          if (params?.curGoodsId && params?.curGoodsId !== goods_id) {
            pageContextCallBack.set('changeColorReady', () => {
              if (goods_id === params?.curGoodsId && params?.externalSizeInfoIndex) {
                commit('newProductDetail/common/updateExternalSizeInfoIndex', params?.externalSizeInfoIndex, { root: true })
              }
            })
          }else {
            pageContextCallBack.clear('changeColorReady')
          }
        },
        // 切换色块
        changeColor: (item = {}) => {
          curExtraBusesData = item
        },
        // 切换mall
        mallClick: (mallInfo) => {
          curExtraMallData = mallInfo
        },
        // 加车成功弹窗推荐
        loadAddBagRecDrawer: async ({ data = {} }) => {
          const { showAddBagRecDrawerSceneOne, showAddBagRecDrawerSceneTwo, showAddBagRecDrawerSceneFour } = showAddBagRecDrawerScene
          if (data?.addCartGoodsId === goods_id && (showAddBagRecDrawerSceneOne || showAddBagRecDrawerSceneTwo || showAddBagRecDrawerSceneFour)) {
            // 时机一提前获取加车推荐数据
            await dispatch('productDetail/Recommend/fetch_rec_data_actions', null, { root: true })
            const status = await dispatch('productDetail/Recommend/open_add_bag_recommend_drawer', null, { root: true })
            return status
          }
          return false
        },
        // 加车成功后的回调
        complete: async ({ data = {}, formData = {} }) => {
          commit('newProductDetail/common/updateAddCompleteStatus', true, { root: true })
          commit('newProductDetail/common/updateAddSkcCompleteStatus', true, { root: true })

          // 加车成功后存储当前商品的主要信息
          dispatch('update_add_to_bag_info', formData)

          if (isFromPromotion) {
            window.vBus && window.vBus.$emit('addToBagSuccess', { data })
          }
          setTimeout(() => {
            window?._gb_cart_tag_tips_?.update({
              excludeScroll: true
            })
            window.vBus && window.vBus.$emit('triggerAddCompletedFloatCart', { animation: false })
          }, 2000)
          window.scrollTo({ top: cacheScroll })
          if (!isReview) {
            if(!data?.loadAddBagRecDrawerStatus) {
              onCloseDoSuccessCallBack = addSuccessCallBack
            }
          } else {
            const { code } = data
            if (+code !== 0) return
            setBounce(true)
            history.go(-1)
            commit('productDetail/CommentPopup/update_close_comment_need_scroll_recomend', true, { root: true })
          }
          if (data?.code == 0) {
            dispatch('show_outfit_content')
            dispatch('newProductDetail/common/updateExposeStatus', {
              addBagStatus: 1,
              addWishStatus: initExposeStatus?.addWishStatus || 0,
            }, { root: true })
            // 从大图模式点击到手价弹出时，点击加车关闭大图
            if (isFromPromotion) {
              commit(
                'newProductDetail/PromotionEnter/updateViewPromotionAddBag',
                (rootState.newProductDetail.PromotionEnter.viewPromotionAddBag + 1),
                { root: true }
              )
            }
              
            let { giftSelectedStatus, giftSelectedList } = giftInfo

            if(giftSelectedList?.length && giftSelectedStatus){
              setTimeout(() => { 
                // 包材料商品批量加车
                dispatch('productDetail/gift/batch_gift_add_to_bag', {
                  gift_selected_list: giftSelectedList,
                }, { root: true })
              }, 1500)
            }
          }
        },
        // 收藏成功后是否显示add to group弹窗
        hookCheckAddToGroup: async ({ state, next }) => {
          if (!isAddWishAfterRec) {
            next()
            return
          }
          const status = await dispatch('productDetail/openDetailRecommendDrawer', {
            name: 'wish',
            extendConfig: {
              showAddBoardBtn: state.show,
            }
          }, { root: true })
          // 如果不展示推荐弹窗，走原逻辑
          if (!status) {
            next()
          } else {
            // 如果拉起弹窗，需要关闭快车保持单例
            appEventCenter.$emit('quickAddOpenAddOn')
          }
        },
        // 收藏状态
        toggleWishStatus: ({ wishStatus }) => {
          commit('newProductDetail/common/updateSaveStatus', wishStatus, { root: true })
          dispatch('newProductDetail/common/updateExposeStatus', {
            addWishStatus: wishStatus && 1,
            addBagStatus: initExposeStatus?.addBagStatus || 0,
          }, { root: true })
        },
        handlePerfectFitShowStatus: (status) => {
          commit('newProductDetail/SizeBox/updateGlobalPerfectFitShow', status, { root: true })
        },
        toggleSizeRecommend: () => {
          if (rule_type === '1' ) {
            dispatch('productDetail/SizeBox/open_bra_size_recommend_pop', null, { root: true })
          } else {
            dispatch('productDetail/SizeBox/open_size_recommend_pop', null, { root: true })
          }
        },
        clickClose: () => {
          if (!isReview) return
          history.go(-1) // 移除 hash
        },
        handleChangeLocalCountry: (value) => {
          // 数据联动控制
          commit('newProductDetail/common/updateCurrentLocalCountry', value, { root: true })
        },
        changeQuantity: (quantity = 1) => {
          commit('newProductDetail/common/updateAddToBagForm', {
            ...addToBagForm,
            quantity: quantity === 0 ? 1 : quantity,
          }, { root: true })
        },
        ...(isFromPromotion || (estimatedInfo?.dataType === ESTIMATED_DATA_TYPES.N_ESTIMATED && !!buyMultipleQuantity)
          ? {}
          : {
            clickEstimated: () => {
              dispatch('productDetail/price/quick_add_click_estimated_tag', { skcInfo: curExtraBusesData }, { root: true })
            },
          }),
        handleSelectGiftList: async (data) => {
          giftInfo = data
        },
      }

      let analysisConfig = {
        // 埋点数据
        from: 'main',
        review_location: isReview ? 'page_review' : '',
        isAttrFold: isAttrFold,
        postData: {
          ...(isFromPromotion ? {
            location: 'popup_promotion',
            threshold: estimatedInfo?.dataType
          } : {}),
          ...(isFromBottomBanner ? {
            location: 'popup_bottomBanner'
          } : {})
        },
        sa: {
          ...(trends_info?.trend_word_id ? {
            passParams: {
              trend_tag: `${trends_info.trend_word_id}-${trends_info.product_select_id}`
            }
          } : {})
        }
      }


      window.fspSDK.observeCustomScene('bsc-quick-add-cart', {
        thresholdLower: 20
      })

      // 加车插件
      const pluginArr = () => {
        const pluginArr = []
        // 立即购
        const buyNowData = {
          needJump: !!goods_id,                      // 是否需要跳转到订单
          autoGetCoupon: false,                      // 是否自定领取优惠券
          autoUpdateCouponList: false,               // 是否自动更新优惠价列表
        }
        pluginArr.push(plugins.buyNowPlugin(buyNowData))
  
          // 礼品包
          if (giftInfo && giftInfo.giftList) {
            pluginArr.push(plugins.giftPlugin(giftInfo, selectGiftList, updateGiftInfo))
          }

        // 一键购
        const ocpBillNo = parseQueryString(location.search)?.billno
        pluginArr.push(plugins.oneClickPayPlugin(ocpBillNo))

        // 加车成功后 (推荐弹窗 / 滚动插件)
        const addCartRecommendPluginData = {
          needDelayScroll: isReview, 
        }
        pluginArr.push(plugins.addCartRecommendPlugin(addCartRecommendPluginData))

        return pluginArr
      }

      const excludePlugins = isBuyNow ? [] : ['BuyNow']

      // 加车配置
      const options = {
        sourceIdentifier: 'detailPage',
        plugins: pluginArr() || [],
        excludePlugins,
        analysisConfig,
        featureConfig: {
          // 是否跳转商详
          needClickToDetail: false,
          // 主商品ID
          mainPageGoodsId: goods_id,  
          // 是否支持直接打开大图
          needMainSalesLargeImage: quickAddLargeImgMode,
          // 展示凑单到手价
          showNoSatisfied: true,
          // 是否展示到手价
          showEstimatedPrice: !closeEstimatedAndAbPrice,
          // 是否展示N件到手价a
          showNEstimatedPrice: !closeEstimatedAndAbPrice,
          // 下游关闭到手价不展示券（谷歌爬虫）
          closeEstimatedAndAbPrice: closeEstimatedAndAbPrice,
          estimatedTagCanClick: !isFromPromotion && !(estimatedInfo?.dataType === ESTIMATED_DATA_TYPES.N_ESTIMATED && !!buyMultipleQuantity),
          // 底部按钮配置
          footerBar: {
            // 是否需要收藏按钮
            needFavoriteButton: true,
          },
        },
        extendedParam: {
          // 主图比例
          imgRatio: fixedRatio,
          // 加车数量
          quantity: Number(buyMultipleQuantity || quantity),
        },
        salesAttrConfig: {
          // 销售属性反显 TODO Nolan Hu 没走通
          selectedAttrIndex: externalSizeInfoIndex,
          // 是否需要相关销售属性 (可下沉到组件)
          showAttrPlusSize: show_multi_layer,
          // 折叠销售属性ab (可下沉到组件)
          saleAttrsFoldAb: show_sale_attr_fold_entry,
          // 当前推荐尺码信息
          recommendMyBraSize: recommendMyBraSize,
          // 当前推荐 Bra 尺码信息
          recommendMySize: recommendMySize,
          // size group 商品商详和快速加车弹窗状态联动
          globalPerfectFitShow: globalPerfectFitShow,
          showTitleSizeGuide: show_sale_attr_fold_entry,
        },
        callbacks: {
          // 加车成功回调
          onAddCartComplete: ({ isSuccess }) => {
      
            if (isFromPromotion) {
              window.vBus && window.vBus.$emit('addToBagSuccess', { data: { code: isSuccess ? 0 : -1 },  delayToast: 0 })
              if (isSuccess) {
                // 从大图模式点击到手价弹出时，点击加车关闭大图
                commit(
                  'newProductDetail/PromotionEnter/updateViewPromotionAddBag',
                  (rootState.newProductDetail.PromotionEnter.viewPromotionAddBag + 1),
                  { root: true }
                )
              }
            }
          },
        },
      }

      // 打开加车
      getQuickAddCartInstance(SCENE_MAP.DETAILS).open({ goods_id, mallCode }, options)
    },
    async get_now_time({
      commit
    }) {
      let data = await schttp({
        url: '/api/common/servicesTime/get'
      })
      let serverTime = data.zzz
      if (serverTime) serverTime = Date.now() / 1000 >> 0
      commit('update_now_time', serverTime)
    }
  },
  getters: {
    is_cur_mall_soldout(state, getters, rootState, rootGetters) {
      const is_sold_out = rootGetters['productDetail/common/is_sold_out']
      const sku_info = rootGetters['productDetail/common/sku_info']
      const mall_code = rootGetters['productDetail/common/mall_code']
      const is_on_sale = rootGetters['productDetail/common/is_on_sale']
      const show_mall_list = rootGetters['productDetail/SizeBox/show_mall_list']
      const mall_info_list = rootGetters['productDetail/SizeBox/mall_info_list']
      if (show_mall_list && is_sold_out && is_on_sale) {
        if (sku_info?.sku_code) {
          return sku_info?.mall_stock?.some?.(item => +item?.stock > 0 && item?.mall_code !== mall_code)
        }
        return mall_info_list?.some?.(item => +item?.stock > 0 && item?.mall_code !== mall_code)
      }
      return false
    },
    cur_mall_info(state, getters, rootState, rootGetters) {
      const mall_code = rootGetters['productDetail/common/mall_code']
      const mall_info_list = rootGetters['productDetail/SizeBox/mall_info_list']
      return mall_info_list?.find?.(item => item?.mall_code === mall_code) || {}
    },
    mall_name(state, getters) {
      return getters?.cur_mall_info?.mall_name
    },
    show_sold_out_module(state, getters, rootState, rootGetters) {
      const is_sold_out = rootGetters['productDetail/common/is_sold_out']
      const un_listed = rootGetters['productDetail/common/un_listed']
      return is_sold_out && !un_listed
    },
    sold_out_tip(state, getters, rootState, rootGetters) {
      const language_v2 = rootGetters['productDetail/common/language_v2']
      if (getters.show_sold_out_module) {
        const { SHEIN_KEY_PWA_20858 = '', SHEIN_KEY_PWA_16794 = '' } = language_v2 || {}
        if (getters.is_cur_mall_soldout) {
          return template(getters?.cur_mall_info?.mall_name, SHEIN_KEY_PWA_20858)
        }
        return SHEIN_KEY_PWA_16794
      }
      return ''
    },
    show_atmosphere(state, getters, rootState, rootGetters) {
      const goodsReady = rootState.newProductDetail?.common?.goodsReady
      const atmosphere_config = rootGetters['productDetail/common/atmosphere_config']
      const atmosphere_data = rootGetters['productDetail/common/atmosphere_data']
      if (getters.show_scription_btn) return false
      return !!atmosphere_config?.addBagShow && goodsReady && !!atmosphere_data?.carHotNews?.length
    },
    show_free_shipping(state, getters, rootState, rootGetters) {
      const ship_info = rootGetters['productDetail/ship_info'] || {}
      return ship_info?.isProductShippingFree === '1'
    },
    show_free_shipping_tip(state, getters, rootState, rootGetters) {
      const fs_abt = rootGetters['productDetail/common/fs_abt'] || {}
      let { freestyle = 'strip' } = fs_abt?.itemfreeshipping?.p || {}
      return getters.show_free_shipping && freestyle === 'button'
    },
    show_free_shipping_tag(state, getters, rootState, rootGetters) {
      const fs_abt = rootGetters['productDetail/common/fs_abt'] || {}
      let { freestyle = 'strip' } = fs_abt?.itemfreeshipping?.p || {}
      return getters.show_free_shipping && freestyle === 'addbag'
    },
    free_shipping_tag_info(state, getters, rootState, rootGetters) {
      let language = rootGetters['productDetail/common/language_v2'] || {}
      let { show_free_shipping_tag } = getters || {}
      return {
        show: show_free_shipping_tag,
        text: language.SHEIN_KEY_PWA_25388,
      }
    },
    has_top_content(state, getters) {
      const { show_sold_out_module, show_atmosphere, show_free_shipping_tip } = getters
      return show_sold_out_module || show_atmosphere || show_free_shipping_tip
    },
    show_store_btn(state, getters, rootState, rootGetters) {
      const fs_abt = rootGetters['productDetail/common/fs_abt']
      // storelefticon的值为( show || none) , default: all
      const storelefticon = fs_abt?.storelefticon?.param?.storelefticon === 'show'
      // iconrange的值为( all || onlyfashion) , default: all
      const iconrange = fs_abt?.storelefticon?.param?.iconrange || 'all'
      const is_in_fashion_store = rootGetters['productDetail/DetailSignBoard/is_in_fashion_store']
      // 是否是品质店铺，品质店铺也会显示店铺icon
      const is_quality_store = rootGetters['productDetail/DetailSignBoard/is_quality_store']
      // 店铺范围是否匹配
      const isMatchStoreRange = iconrange === 'all' || is_in_fashion_store || is_quality_store
      const  excludeSiteUids = gbCommonInfo?.DETAIL_SECOND_CONFIG?.ADD_TO_BAG_ICON || []
      const { store_code, storeStatus, link } = rootGetters['productDetail/DetailSignBoard/store_detail'] || {}
      // storeStatus === 1 表示店铺启用
      return storelefticon && isMatchStoreRange && !excludeSiteUids.includes(gbCommonInfo?.SiteUID) && store_code !== 1 && Number(storeStatus) === 1 && link.indexOf('/store/home') > -1
    },
    show_cart_btn(state, getters, rootState, rootGetters) {
      const fs_abt = rootGetters['productDetail/common/fs_abt']
      return fs_abt?.collectandaddcart?.p?.collectandaddcart === 'A'
    },
    show_scription_btn(state, getters, rootState) {
      // TODO: 是否显示订阅按钮
      return rootState.productDetail.coldModules.productInfo?.is_subscription === '1'
    },
    benefit_info(state, getters, rootState, rootGetters) {
      let addCartInfo = rootGetters['productDetail/add_cart_info']
      let tipsList = addCartInfo?.addCartButton?.tipsList || []
      let { buy_now_info, one_click_pay_info } = getters 
      if (!tipsList?.length) return null
      if (buy_now_info.show || one_click_pay_info?.showOneClickPayBtn) return null
      let result = (() => {
        let estimatedInfo = rootGetters['productDetail/price/estimated_info']
        for (let i of tipsList) {
          if (i == BENEFIT_BENEFI_MAP.inventory) {
            // 库存（不足）
            let tip = rootGetters['productDetail/common/will_cart_button_sold_out_tip']
            if (tip) return {
              type: BENEFIT_BENEFI_MAP.inventory,
              value: tip
            }
          }
          if (i == BENEFIT_BENEFI_MAP.countdown) {
            // 到手价\闪购，倒计时
            let flashBeltWraperInfo = rootGetters['productDetail/price/flash_belt_wraper_info']
            let { endTime: esEndTime } = estimatedInfo || {}
            let { endTime: flashEndTime } = flashBeltWraperInfo || {}
            let isBrandFlash = rootGetters['productDetail/promotion/is_brand_flash'] 
            let endTime = esEndTime || flashEndTime
            let nowTime = state.nowTime
            if (endTime) {
              if (endTime <= nowTime) continue
              let differDayNum = (endTime - nowTime) / (24 * 60 * 60)
              let countDownDay = isBrandFlash ? 3 : 1
              if (differDayNum > countDownDay) continue
            } else {
              continue
            }
            return {
              type: BENEFIT_BENEFI_MAP.countdown,
              value: endTime,
              nowTime
            }
          }
          if (i == BENEFIT_BENEFI_MAP.sameitemprice) {
            // 同款最低价	best deal on same item
            let bestDealText = rootState.productDetail?.hotModules?.beltInfo?.sameLabelBelt?.lowestPriceTips || ''
            if (bestDealText) return {
              type: BENEFIT_BENEFI_MAP.sameitemprice,
              value: bestDealText
            }
          }
          if (i == BENEFIT_BENEFI_MAP.historyprice) {
            // 历史最低价 N天最低价,文案 lowest price in x days
            let lowestPriceTips = rootGetters['productDetail/price_info']?.lowestPrice?.lowestPriceTips || ''
            if (lowestPriceTips) return {
              type: BENEFIT_BENEFI_MAP.historyprice,
              value: lowestPriceTips
            }
          }
          if (i == BENEFIT_BENEFI_MAP.normaldiscount) {
            // 折扣说明当商详，展示售价/到手价折扣率时，则可以展示 x% off disount，有到手价时，取到手价折扣率，没有时取活动折扣率
            let { normalDiscountLanguage } = addCartInfo?.addCartButton || {}
            if (estimatedInfo) {
              let { SATISFY, AB, AB_OVERLAP_SATISFY, AB_OVERLAP_NO_SATISFY } = ESTIMATED_DATA_TYPES
              if ([SATISFY, AB, AB_OVERLAP_SATISFY, AB_OVERLAP_NO_SATISFY].includes(estimatedInfo?.dataType)) {
                return {
                  type: BENEFIT_BENEFI_MAP.normaldiscount,
                  value: template(`${estimatedInfo.couponDiscount}%`, normalDiscountLanguage)
                }
              }
            }
            let { discountPercentNum } = rootGetters['productDetail/price/discount_info']
            if (discountPercentNum) return {
              type: BENEFIT_BENEFI_MAP.normaldiscount,
              value: template(`${discountPercentNum}%`, normalDiscountLanguage)
            }
          }
        }
        return null
      })()

      return result
    },
    add_btn_info(state, getters, rootState, rootGetters) {
      let { benefit_info, buy_now_info, one_click_pay_info } = getters 
      let realTimeReady = rootGetters['productDetail/common/real_time_ready']
      let addCartInfo = rootGetters['productDetail/add_cart_info']
      let isCustomization = rootGetters['productDetail/common/is_customization']
      let language = rootGetters['productDetail/common/language_v2']
      let estimatedInfo = rootGetters['productDetail/price/estimated_info']
      let fsAbt = rootGetters['productDetail/common/fs_abt'] || {}
      let isHitComplianceMode = rootGetters['productDetail/common/is_hit_compliance_mode']
      let complianceMode = rootGetters['productDetail/common/compliance_mode']
      let complianceTipsMode = rootGetters['productDetail/common/compliance_tips_mode']
      let complianceModeDe = rootGetters['productDetail/common/compliance_mode_de']
      let isInversion = rootGetters['productDetail/price/is_inversion']
      let { price } = rootGetters['productDetail/price/price_common_infos'] || {}
      let mainPriceInfo = rootGetters['productDetail/price/main_price_info'] || {}
      let isFlash = rootGetters['productDetail/promotion/is_flash']
      let goodsId = rootGetters['productDetail/common/goods_id']
      const isNormalFlash = rootGetters['productDetail/promotion/is_normal_flash'] 
      const isBrandFlash = rootGetters['productDetail/promotion/is_brand_flash'] 
      let isSpecialFlash = rootGetters['productDetail/promotion/is_special_flash']
      
      let suggestedSalePriceInfo = rootGetters['productDetail/price/suggested_sale_price_info'] || {}
      
      let isPaidUser = rootGetters['productDetail/common/is_paid_user']
      let sheinClubPromotionInfo = rootGetters['productDetail/promotion/shein_club_promotion_info']
      
      let { SHEIN_KEY_PWA_26707, SHEIN_KEY_PWA_15019 } = language

      let { Interestpointstyle, DetailBuynow, middleast } = fsAbt
      let InterestpointstyleAbtObvious = Interestpointstyle?.p?.Interestpointstyle === 'obvious'
      let isDetailBuyNowPriceCeiling = DetailBuynow?.p?.quickprice === 'Ceiling'
      let isGiftSelectedList = rootState.productDetail.gift?.gift_selected_list?.length > 0


      let [textColor, imgSrc] = (() => {
        if (!InterestpointstyleAbtObvious) return ['', '']
        if (buy_now_info.show || one_click_pay_info?.showOneClickPayBtn) return ['', '']
        if (!benefit_info) return ['', '']

        let addCartInfos = addCartInfo?.addCartButton?.addCartInfos || []
        let addCartInfoItem = addCartInfos.find(item => item.promptCode === benefit_info.type) || {}
        let { iconPickType, iconType } = addCartInfoItem || {}
        let iconMap = {
          normalicon: '',
          flashsaleicon: '',
          brandsaleicon: '',
          sheinclubicon: '',
        }
        iconType?.forEach?.(item => {
          if (iconPickType == 1) {
            iconMap[item.iconCode] = item.staticImg
          }
          if (iconPickType == 2) {
            iconMap[item.iconCode] = item.dynamicImg
          }
        })
        if (isHitComplianceMode) return ['#FFF', iconMap.normalicon]
        if (isPaidUser && sheinClubPromotionInfo) return ['#FFBA97', iconMap.sheinclubicon]
        if (isFlash) {
          if (isBrandFlash) return ['#FF8957', iconMap.brandsaleicon]
          if (isNormalFlash) return ['#FFEDA2', iconMap.flashsaleicon]
          if (isSpecialFlash) return ['#FFF', iconMap.flashsaleicon]
        }
        return ['#FFF', iconMap.normalicon]
      })()


      let btnStyle = (() => {
        if (one_click_pay_info?.showOneClickPayBtn) {
          return {
            background: '#FFF',
            border: '1px solid #000'
          }
        }
        return {
          background: '#222',
        }
      })()

      // 主文字 start
      let addTextInfo = (() => {
        let value = isCustomization ? SHEIN_KEY_PWA_26707 : SHEIN_KEY_PWA_15019
        let color = textColor || '#FFF'
        let fontSize = '.4rem'
        let positionTop = true
        let positionBottom = false
        if (InterestpointstyleAbtObvious) {
          color = (() => {
            if (isHitComplianceMode) return '#FFF'
            if (isPaidUser && sheinClubPromotionInfo) return '#FFBA97'
            if (isBrandFlash) return '#FF8957'
            if (isNormalFlash) return '#FFEDA2'
            return '#FFF'
          })()
          positionTop = false
          positionBottom = true
          if (benefit_info) {
            fontSize = '.32rem'
          }
        }
        if (one_click_pay_info?.showOneClickPayBtn) {
          color = '#000'
          fontSize = '.373333rem'
        }
        return {
          value,
          style: { fontSize, color },
          positionTop,
          positionBottom
        }
      })()
      // 主文字 end



      // 附文字 - 价格 start
      let { SATISFY, AB, AB_OVERLAP_SATISFY, AB_OVERLAP_NO_SATISFY } = ESTIMATED_DATA_TYPES
      let priceInfo = (() => {
        if (one_click_pay_info?.showOneClickPayBtn) return null
        if (isDetailBuyNowPriceCeiling) return null
        if (isGiftSelectedList) return null
        let value = ''
        let beforeText = ''
        let fontSize = InterestpointstyleAbtObvious ? '14px' : '12px'
        let color = textColor
        let fontWeight = InterestpointstyleAbtObvious ? 700 : 400

        if (estimatedInfo && !complianceMode) {
          if ([SATISFY, AB, AB_OVERLAP_SATISFY, AB_OVERLAP_NO_SATISFY].includes(estimatedInfo?.dataType)) {
            value = estimatedInfo.value.amountWithSymbol
            beforeText = (() => {
              let { SHEIN_KEY_PWA_31593, SHEIN_KEY_PWA_24939, SHEIN_KEY_PWA_33263 } = language
              if (InterestpointstyleAbtObvious) return template('', SHEIN_KEY_PWA_31593) || 'Est.'
              if (middleast?.p?.middleast === 'new' && [AB, AB_OVERLAP_SATISFY].includes(estimatedInfo.dataType)) {
                return template('', SHEIN_KEY_PWA_33263)
              }
              return template('', SHEIN_KEY_PWA_24939)
            })()
            color = (() => {
              if (textColor) return textColor
              if (InterestpointstyleAbtObvious && !buy_now_info.show && !one_click_pay_info?.showOneClickPayBtn) {
                if (isHitComplianceMode) return '#FFF'
                if (isPaidUser && sheinClubPromotionInfo) return '#FFBA97'
                if (isBrandFlash) return '#FF8957'
                if (isNormalFlash) return '#FFEDA2' 
                return '#FFF'
              }
              return '#FA6338'
            })()
            return {
              beforeText,
              value,
              style: {
                fontSize,
                color,
                fontWeight
              }
            }
          }
        }

        let { finalPrice } = rootGetters['productDetail/price/other_price_info'] || {}
        let priceFrom = rootGetters['productDetail/price/price_from'] || {}
        let discountInfo = rootGetters['productDetail/price/discount_info'] || {}
        let fromTempText = priceFrom.show ? priceFrom.value : ''
        value = `${fromTempText} ${finalPrice?.amountWithSymbol}`
        color = (() => {
          if (textColor) return textColor
          if (complianceTipsMode || complianceModeDe || isInversion) return '#FFF'
          if (isPaidUser && sheinClubPromotionInfo) return '#FFBA97'
          if (discountInfo.show) return '#FA6338'
          if ([SATISFY, AB, AB_OVERLAP_SATISFY, AB_OVERLAP_NO_SATISFY].includes(estimatedInfo?.dataType)) return '#FA6338'
          if (isFlash) return '#FA6338'
          return '#FFF'
        })()
        return {
          beforeText,
          value,
          style: {
            fontSize,
            color,
            fontWeight: 700
          }
        }
      })()
      // 附文字 - 价格 end


      // 附文字 - 原价 start
      let retailPriceinfo = (() => {
        if (one_click_pay_info?.showOneClickPayBtn) return null
        if (isDetailBuyNowPriceCeiling) return null
        if (isGiftSelectedList) return null
        if (benefit_info) return null
        if (isInversion) return null
        if (isHitComplianceMode) {
          if (complianceTipsMode || complianceModeDe) return null
          return suggestedSalePriceInfo.fr.value || null
        }
        if (estimatedInfo) return null
        if (mainPriceInfo.value === price.retailPrice.amountWithSymbol) return null
        return price.retailPrice.amountWithSymbol
      })()
      // 附文字 - 原价 start


      // 附文字 - 利益点 start
      let benefitInfo = (() => {
        if (!benefit_info) return null
        return {
          type: benefit_info.type,
          value: benefit_info.value,
          imgSrc,
          nowTime: benefit_info.nowTime,
          style: {
            color: textColor || '#FFF'
          },
        }
      })()
      // 附文字 - 利益点 start

      return {
        realTimeReady,
        textColor,
        addTextInfo,
        btnStyle,
        priceInfo,
        retailPriceinfo,
        benefitInfo,
        isCustomization,
        goodsId
      }
    },
    buy_now_info(state, getters, rootState, rootGetters) {
      let addCartInfo = rootGetters['productDetail/add_cart_info'] || {}
      let goodsId = rootGetters['productDetail/common/goods_id']
      let shipInfo = rootGetters['productDetail/ship_info'] || {}
      let fsAbt = rootGetters['productDetail/common/fs_abt'] || {}
      let skuInfo = rootGetters['productDetail/common/sku_info']
      let skuList = rootGetters['productDetail/common/sku_list'] || []
      let { buyNowInfo } = addCartInfo || {}
      let {
        // appTraceReason,
        buyNowTips,
        isQuickShipShowBuyNow,
        isShowBuyNow,
        isUnShow,
      } = buyNowInfo || {}

      let show = (() => {
        if (isShowBuyNow == 1) return true
        if (isQuickShipShowBuyNow == 1) {
          if (shipInfo?.hasQuickShipTime != 1) return false
          if (fsAbt.DetailPageQuickShipShow?.param != 'DetailShowQuick') return false
          if (skuInfo?.sku_code) {
            return skuInfo.isSupportQuickShip == 1
          }
          return !!skuList.find(item => item.isSupportQuickShip == 1)
        }
        return false
      })()

      const analyzeData = {
        expose: {
          id: '1-6-1-190',
          data: { 
            activity_from: 'main',
            location: 'page',
            is_buynow: '1',
            goods_id: goodsId,
            nobuynow_reason: ''
          }
        },
        exposeEligShow: isUnShow ? {
          daId: '1-6-1-199',
          extraData: {
            goods_id: goodsId
          }
        } : null,
      }

      return {
        show,
        text: buyNowTips,
        analyzeData,
      }
    },
    one_click_pay_info(state, getters, rootState, rootGetters) {
      let addCartInfo = rootGetters['productDetail/add_cart_info']
      const { productDetailCheckOcbVO } = addCartInfo || {}
      const { supportOneClickPay, skuSupportOneClickPayInfos } = productDetailCheckOcbVO || {}
      if (supportOneClickPay == 1) {
        let showOneClickPayBtn = true
        const sku_info = rootGetters['productDetail/common/sku_info'] 
        if (sku_info?.sku_code) {
          showOneClickPayBtn = !!(skuSupportOneClickPayInfos?.find?.(item => item.skuCode === sku_info.sku_code)?.supportOneClickPay == 1)
        }
        return {
          showOneClickPayBtn,
          billInfo: productDetailCheckOcbVO,
        }
      }
      return null
    },
    estimated_no_threshold_floor_info(state, getters, rootState, rootGetters) {
      let estimatedInfo = rootGetters['productDetail/price/estimated_info']
      if (!estimatedInfo) return null
      if (!estimatedInfo?.noThresholdFloor) return null
      if (!estimatedInfo.otherInfo.showNoSatisfied) return null
      return estimatedInfo
    }
  }
}
