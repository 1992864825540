import { getBffOrderInfo, getBffVirtualOrderInfo } from 'public/src/services/api/pay/payment'
import { PRODUCT_TYPE, CHECKOUT_TYPE } from '@shein-aidc/bs-sdk-libs-pay'

function findPaymentAll(data, predicate = () => true) {
  const queue = [...data] // 使用队列来处理广度优先搜索
  const result = []
  while (queue.length > 0) {
    const node = queue.shift() // 获取并移除队列的第一个元素
    if (node && predicate(node)) result.push(node) // 找到匹配的节点，push该节点
    // 将子节点追加到队列
    if (node?.payments && node?.payments?.length > 0) {
      queue.push(...node.payments)
    }
  }

  return result // 没有找到匹配的节点，返回 null
}

const uniqueBusinessInfo = (list = []) => {
  // 需去重
  let hashMap = {}
  return list.reduce((pre, cur) => {
    const hash = `${cur.businessModel}:${cur.companyId || ''}`
    if (!hashMap[hash]) {
      hashMap[hash] = true
      return [...pre, cur]
    }
    return pre
  }, [])
}

const getSessionPayMethodList = (payments) => {
  // 默认走inline流程的支付方式
  const defaultInlineMethods = ['klarna-sliceitdirect', 'PayPal-bnpl']
  const getDefaultInlineMethods = (paymentItem) => {
    return (paymentItem.inlineChannel || defaultInlineMethods.includes(paymentItem.code)) && +paymentItem.enabled === 1
  }

  const channelPayments = findPaymentAll(payments, getDefaultInlineMethods) || []

  return channelPayments.map(v => v.code) || []
}

const getDefaultPayAgainStatus = () => ({
  autoOpenPaymentOptions: false
})

export function payAgainStateFactory() {
  return {
    bffOrderInfo: {},
    oldOrderInfo: {},
    selectedPaymentInfo: {},
    payAgainStatus: getDefaultPayAgainStatus(),
    payInfoForOrderShow: {},
    createOrderBinDialog: {
      show: false,
      coverData: {}
    },
    signupPayVault: {}
  }
}

export default {
  namespaced: true,
  state: payAgainStateFactory,
  getters: {
    // checkoutInfo
    paymentCheckoutInfo(state) {
      const { 
        shipping_address = {}, // 虚拟订单详情返回的地址信息
        shippingaddr_info = {}, 
        orderCurrency = {},
        totalPrice = {},
        has_platform_goods = '',
        sorted_price = [],
        vip_order_source = '0',
        coBrandedCardIntegral = '',
        currency_code,
        is_virtual_order = false, // 是否是虚拟订单
        token_id = '',
      } = state.bffOrderInfo || {}

      const shippingaddrInfo = is_virtual_order ? shipping_address : shippingaddr_info

      const addressInfo = {
        countryCode: shippingaddrInfo?.shipping_country_code || '',
        countryId: shippingaddrInfo?.shipping_country_id || '',
        tel: shippingaddrInfo?.shipping_telephone || '',
      }
      return {
        addressInfo,
        orderCurrencyInfo: !is_virtual_order ? orderCurrency || {} : {
          code: currency_code
        },
        currencyCode: orderCurrency?.code || currency_code || '',
        totalPriceInfo: totalPrice || {},
        has_platform_goods: has_platform_goods || '',
        sortedPriceInfo: sorted_price,
        virtualOrderScene: vip_order_source,
        coBrandedCardIntegral,
        token_id
      }
    },
    paymentSceneInfo (state) {
      // order_type 1 普通订单
      // order_type 3 礼品卡订单
      // order_type 5 付费会员单独购
      // order_type 6 超省卡单独购
      const { order_type = '' } = state.bffOrderInfo || {}

      let productType = PRODUCT_TYPE.NORMAL
      let checkoutType = CHECKOUT_TYPE.NORMAL_AGAIN
      switch (+order_type) {
        case 3:
          productType = PRODUCT_TYPE.GIFT_CARD
          checkoutType = CHECKOUT_TYPE.GIFTCARD_AGAIN
          break
        case 5:
          productType = PRODUCT_TYPE.PAID_MEMBERSHIP
          checkoutType = CHECKOUT_TYPE.BUYPRIME_AGAIN
          break
        case 6:
          productType = PRODUCT_TYPE.PAID_SHEIN_SAVER
          checkoutType = CHECKOUT_TYPE.XTRA_AGAIN
          break
        default:
          productType = PRODUCT_TYPE.NORMAL
          checkoutType = CHECKOUT_TYPE.NORMAL_AGAIN
      }

      return {
        productType: productType,
        checkoutType: checkoutType
      }
    },
    paymentInfo (state) {
      // const paymentSuggestion = state.payAgainStatus?.autoOpenPaymentOptions ? '' : state?.bffOrderInfo?.paymentSuggestion || ''
      const paymentSuggestion = state?.bffOrderInfo?.paymentSuggestion || ''
      console.log('order_new===state.bffOrderInfo===', state.bffOrderInfo, state.payAgainStatus?.autoOpenPaymentOptions, paymentSuggestion)
      return Object.assign({}, state?.bffOrderInfo?.payment_data || {}, {
        paymentSuggestion,
      })
    },
    paymentConfig (state, getter) {
      return Object.assign({}, getter?.paymentSceneInfo || {})
    },
    // 创建session时的order_details
    // 需要加上超省卡/付费会员的业务模式传给后端
    channelSession (state) {
      const { is_virtual_order } = state.bffOrderInfo || {}
      const mall_list = !is_virtual_order ? state.bffOrderInfo?.mall_list || [] : state.oldOrderInfo?.mall_list || []
      const list = (mall_list || []).map(v => ({ businessModel: v.business_model, companyId: v.company_id }))
      return uniqueBusinessInfo(list)
    },
    updatePaymentInfo (state, getter) {
      const { addressInfo = {}, totalPriceInfo = {}, orderCurrencyInfo = {} } = getter.paymentCheckoutInfo || {}
      const { payments = [] } = getter.paymentInfo || {}
      const { channelSession = [] } = getter || {}

      return {
        countryCode: addressInfo.countryCode || '',
        orderAmount: totalPriceInfo?.amount || '',
        orderCurrency: orderCurrencyInfo?.code || '',
        payMethodList: getSessionPayMethodList(payments),
        channelSession: JSON.stringify({ orderDetails: channelSession || [] })
      }
    },
    /**
     * 当前选中的支付方式信息
     *
     * @param {*} state
     * @param {*} getter
     */
    selectedPaymentItemStatus (state, getter) {
      const { folded_position = '', paymentSuggestion = '', payments = [] } = getter.paymentInfo || {}
      const { signupFlag, payment_code, use_one_time_sign } = state.selectedPaymentInfo || {}

      const foldedPosition = folded_position

      const paymentIndex = payments?.findIndex?.(item => item.code === payment_code) || -1
      const foldedPosIndex = +(foldedPosition || 1) - 1

      let is_vaulting = '-'
      let is_choose_foldedpayment = paymentIndex > foldedPosIndex ? '1' : '0'
      let is_default = paymentSuggestion === payment_code ? '1' : '0'
      let token_status = use_one_time_sign ? 'token_onetime' : 'token_status'

      if (payment_code === 'PayPal-GApaypal') {
        is_vaulting = (+signupFlag === 1 || !use_one_time_sign) ? '1' : '0'
      }

      return {
        is_vaulting,
        is_choose_foldedpayment,
        is_default,
        token_status
      }
    },
    // paypal 支付按钮显示状态
    isShowPaypalVenmoBtn(state) {
      return state.selectedPaymentInfo?.payment_code === 'PayPal-Venmo'
    },
    isShowApplePayBtn(state) {
      return +state?.selectedPaymentInfo?.is_apple_pay === 1 && !!state?.selectedPaymentInfo.show_channel_btn
    },
    isShowPaypalGaButton(state) {
      return state.selectedPaymentInfo?.payment_code === 'PayPal-GApaypal' && state.selectedPaymentInfo?.show_channel_btn
    },
    isShowPaypalBnplButton(state) {
      return state.selectedPaymentInfo?.payment_code === 'PayPal-bnpl'
    },
    isSupportApplePay (state, getter) {
      const payments = getter.paymentInfo?.payments || []
      return payments.some((item) => +item.is_apple_pay === 1 && +item.enabled === 1) || getter.isShowApplePayBtn
    },
    isShowChannelBtn (state, getter) {
      console.log('getter.isShowPaypalVenmoBtn===', getter.isShowPaypalVenmoBtn, getter.isShowApplePayBtn, getter.isShowPaypalGaButton, getter.isShowPaypalBnplButton)
      return getter.isShowPaypalVenmoBtn || getter.isShowApplePayBtn || getter.isShowPaypalGaButton || getter.isShowPaypalBnplButton
    },
    isShowPaypalChannelBtn (state, getter) {
      return getter.isShowPaypalVenmoBtn || getter.isShowPaypalGaButton || getter.isShowPaypalBnplButton
    },
    customOrderType (state, getter) {
      switch (getter.paymentSceneInfo?.checkoutType) {
        case CHECKOUT_TYPE.BUYPRIME_AGAIN:
          return 'buyprime_again'
        case CHECKOUT_TYPE.XTRA_AGAIN:
          return 'xtra_again'
        default:
          return ''
      }
    },
    isShowPaypalButton (state, getter) {
      return getter.isShowPaypalGaButton || getter.isShowPaypalBnplButton
    }
  },
  mutations: {
    updateBffOrderInfo (state, payload) {
      // console.log('order_new===updateBffOrderInfo', payload)
      state.bffOrderInfo = Object.assign({}, payload || {})
    },
    updateCreateOrderBinDialog (state, payload) {
      state.createOrderBinDialog = Object.assign({}, payload || {})

      console.log('order_new===updateCreateOrderBinDialog', state.createOrderBinDialog)
    },
    updateSelctedPaymentInfo (state, payload) {
      console.log('order_new===updateSelctedPaymentInfo', payload)
      state.selectedPaymentInfo = Object.assign({}, payload)
    },
    updatePayInfoForOrderShow (state, payload) {
      state.payInfoForOrderShow = Object.assign({}, payload)
    },
    // 兼容虚拟商品订单
    assignOldOrderInfo (state, payload) {
      state.oldOrderInfo = Object.assign({}, payload || {})
    },
    updatePayAgainStatus (state, payload) {
      state.payAgainStatus = Object.assign(state.payAgainStatus, payload || {})
    },
    updateSignupPayVault (state, payload) {
      console.log('order_new===updateSignupPayVault', payload)
      state.signupPayVault = Object.assign({}, payload || {})
    }
  },
  actions: {
    async fetchBffOrderInfo ({ commit }, payload) {
      let res
      const tempParams = {
        billno: payload?.billno || '',
      }
      if (payload?.tokenId) {
        tempParams.tokenId = payload.tokenId
      }
      if (payload.is_virtual_order) {
        res = await getBffVirtualOrderInfo({
          ...tempParams,
          page_from: 'order',
        })
      } else {
        res = await getBffOrderInfo({
          ...tempParams,
          page_from: 'order',
        })
      }
      if (res.code === '0' && res.info) {
        commit('updateBffOrderInfo', {
          billno: res.info?.billNo,
          ...res.info
        })
      }
      return res || {}
    },
    resetPayAgainState ({ commit }) {
      commit('updateBffOrderInfo', {})
      commit('updateSelctedPaymentInfo', {})
      commit('updateCreateOrderBinDialog', {})
      commit('updatePayInfoForOrderShow', {})
      commit('assignOldOrderInfo', { ...getDefaultPayAgainStatus() })
    }
  }
}

