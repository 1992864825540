import { template } from '@shein/common-function'
import { isLogin } from 'public/src/pages/common/utils/index.js'
import schttp from 'public/src/services/schttp'
import { getLocalStorage } from '@shein/common-function'

const setAddressCache = async ({ addressId, countryId, stateId, cityId, districtId, countryName, city, state, district }) => {
  const { SiteUID, user } = gbCommonInfo
  let createdTime = new Date().getTime()
  let addressCookie = {
    addressId,
    countryId,
    stateId,
    cityId,
    districtId,
    createdTime,
    memberId: user?.member_id || '',
    loginTimeLast: user?.login_time_last || '',
    isUserHandle: false,
    siteUid: SiteUID,
    state, // 省
    city, // 城市
    district, // 区
  }
  const getRealSet = (obj) => {
    let realSetData = {}
    Object.keys(obj).forEach(val => {
      if (obj[val]) {
        realSetData[val] = obj[val]
      }
    })
    return realSetData
  }
  const res = await schttp({
    method: 'POST',
    url: '/api/common/busineseCache/update',
    data: {
      cacheData: getRealSet(addressCookie),
      scanType: 'addrcache'
    }
  })
  if (res?.code == '0') localStorage.setItem('addressCookie', JSON.stringify(getRealSet(addressCookie)))

  let addressCache = {
    ...(countryId ? { [countryId]: countryName } : {}),
    ...(cityId ? { [cityId]: city } : {}),
    ...(stateId ? { [stateId]: state } : {}),
    ...(districtId ? { [districtId]: district } : {}),
  }
  if (JSON.stringify(addressCache) !== '{}') {
    localStorage.setItem('address_cache', JSON.stringify(getRealSet(addressCache)))
  } else {
    localStorage.removeItem('address_cache')
  }
}

export default {
  namespaced: true,
  state: {
    /* title */
    user_address_list: [],
    is_user_update_address: false,
    address_cache: {},
    address_cookie: {},
    drawer_shipping_info_open: false,
    /* title */

    /* cod retuen */
    cod_policy_drawer_data: null,
    return_policy_drawer_data: null,
    drawer_return_cod_open: 0,
    /* cod retuen */


    /* seller */
    detail_store_seller_info: null,
    drawer_shipping_main_drawer_open: false,
    drawer_report_open: false,
    drawer_report_us_open: false,
    drawer_report_das_open: false,
    drawer_report_form_status: false,
    /* seller */

    drawer_shipping_guarantee_open: false,

  },
  mutations: {
    update_drawer_shipping_main_drawer_open(state, payload) {
      state.drawer_shipping_main_drawer_open = payload
    },
    update_drawer_shipping_guarantee_open(state, payload) {
      state.drawer_shipping_guarantee_open = payload
    },
    update_drawer_shipping_info_open(state, payload) {
      state.drawer_shipping_info_open = payload
    },
    update_drawer_return_cod_open(state, payload) {
      state.drawer_return_cod_open = payload
    },
    update_drawer_report_open(state, payload) {
      state.drawer_report_open = payload
    },
    update_drawer_report_us_open(state, payload) {
      state.drawer_report_us_open = payload
    },
    update_drawer_report_das_open(state, payload) {
      state.drawer_report_das_open = payload
    },
    update_drawer_report_form_status(state, payload) {
      state.drawer_report_form_status = payload
    },
    update_user_address_list(state, payload) {
      state.user_address_list = payload
    },
    update_address_cache(state, payload) {
      let { address_cache, addressCookie } = payload || {}
      state.address_cache = address_cache
      state.address_cookie = addressCookie
    },
    update_is_user_update_address(state) {
      state.is_user_update_address = true
    },
    update_detail_store_seller_info(state, payload) {
      state.detail_store_seller_info = payload
    },
    update_cod_policy_drawer_data(state, payload) {
      state.cod_policy_drawer_data = payload
    },
    update_return_policy_drawer_data(state, payload) {
      state.return_policy_drawer_data = payload
    }
  },
  actions: {
    init({ getters, dispatch, commit }) {
      let addressCookie = localStorage.getItem('addressCookie')
      let { shipInfo } = getters.common_info || {}
      if (!addressCookie) {
        if (!shipInfo.countryId) {
          // /bff-api/product/get_goods_detail_user_address
          setAddressCache({
            countryId: 226,
            countryName: 'United States'
          })
          setTimeout(() => {
            let { goodsId, mallCode }  = getters.common_info || {}
            dispatch('newProductDetail/asyncHotModles', {
              goods_id: goodsId,
              mallCode
            }, { root: true })
            dispatch('async_address_cache')
            dispatch('get_user_default_address')
          }, 500)
        } else {
          setAddressCache({
            countryId: shipInfo.countryId,
            countryName: shipInfo.shipping_countryname || 'United States'
          })
          setTimeout(() => {
            dispatch('async_address_cache')
            dispatch('get_user_default_address')
          }, 500)
        }
      } else {
        dispatch('async_address_cache')
        dispatch('get_user_default_address')
      }
      commit('update_detail_store_seller_info', null)
    },
    async set_shipping_address_info_to_local_storage({ state, dispatch }) {
      let { is_user_update_address, user_address_list } = state
      if (!is_user_update_address && user_address_list) {
        let userAddress = user_address_list?.find?.(item => item.isDefault == 1) || user_address_list[0] || null
        if (!userAddress) return
        let { addressId, countryId, stateId, cityId, districtId, countryName, city, state, district } = userAddress
        await setAddressCache({ addressId, countryId, stateId, cityId, districtId, countryName, city, state, district })
      } else {
        // TODO: 旧的shipping drawer 接入后，用户触发的更新地址在这里存入localStorage
      }
      dispatch('async_address_cache')
    },
    async_address_cache({ commit }) {
      let address_cache = JSON.parse(localStorage.getItem('address_cache'))
      let addressCookie = JSON.parse(localStorage.getItem('addressCookie'))
      commit('update_address_cache', { address_cache, addressCookie })
    },
    async get_user_default_address({ commit, dispatch, state, getters }) {
      if (!isLogin()) return
      const { info = {} } = await schttp({
        method: 'GET',
        url: '/api/user/addressbook/limitAddr/query',
        params: {
          isSiteLimit: 1
        },
      })
      const { address = [] } = info || {}
      commit('update_user_address_list', address)
      let addressId = getLocalStorage('addressCookie')?.addressId
      if (!addressId) {
        let { goodsId, mallCode }  = getters.common_info || {}
        let userAddress = address?.find?.(item => item.isDefault == 1) || state.user_address_list[0] || null
        if (userAddress && addressId != userAddress?.addressId) {
          await dispatch('set_shipping_address_info_to_local_storage')
          setTimeout(() => {
            dispatch('newProductDetail/asyncHotModles', {
              goods_id: goodsId,
              mallCode
            }, { root: true })
          }, 1000)
        }
      } else {
        dispatch('set_shipping_address_info_to_local_storage')
      }
    },
    async get_detail_store_seller_info({ state, getters, commit }) {
      if (state.detail_store_seller_info) return null
      const { businessModel, store_info } = getters.common_info || {}
      const timeout = ms => new Promise((resolve) => setTimeout(() => resolve(), ms)) // 解决首次请求过快导致loading闪现问题
      let res = await Promise.all([schttp({
        method: 'GET',
        url: '/product/detail/seller_info',
        useBffApi: true,
        params: {
          businessModel,
          storeCode: store_info.store_code
        },
      }), timeout(400)])
      commit('update_detail_store_seller_info', res[0]?.info)
    },
    async query_return_policy_drawer_data({ getters, commit }) {
      let { catId, goodsSn, mallCode, countryId, businessModel } = getters.common_info
      let result = await schttp({
        method: 'GET',
        url: '/products-api/return_policy',
        useBffApi: true,
        params: {
          cat_id: catId,
          skc: goodsSn,
          country_id: countryId,
          mall_code: mallCode,
          businessModel
        },
      })
      commit('update_return_policy_drawer_data', result.info)
    },
    async query_cod_policy_drawer_data({ getters, commit }) {
      let { countryId } = getters.common_info
      let result = await schttp({
        method: 'GET',
        url: '/products-api/cod_policy',
        useBffApi: true,
        params: {
          country_id: countryId,
        },
      })
      commit('update_cod_policy_drawer_data', result.info)
    },
  },
  getters: {
    common_info(state, getters, rootState, rootGetters) {
      let { GB_cssRight, langPath, lang, SiteUID, host, user, GB_cssRight_rp } = gbCommonInfo || {}
      let fsAbt = rootGetters['productDetail/common/fs_abt']
      let language = rootGetters['productDetail/common/language_v2']

      let goodsSn = rootGetters['productDetail/common/goods_sn']
      let goodsId = rootGetters['productDetail/common/goods_id']
      let skuInfo = rootGetters['productDetail/common/sku_info']
      let skuList = rootGetters['productDetail/common/sku_list']
      let store_info = rootGetters['productDetail/common/store_info']
      let labelList = rootGetters['productDetail/common/label_list']

      let mallInfo = rootState.productDetail.coldModules.mallInfo
      let mallCode = rootGetters['productDetail/common/mall_code']
      let isPaidUser = rootGetters['productDetail/common/is_paid_user']
      let sheinClubPromotionInfo = rootGetters['productDetail/promotion/shein_club_promotion_info']
      let hasDetailStoreSellerInfo = rootGetters['productDetail/common/has_detail_store_seller_info']
      let businessModel = store_info.business_model || ''
      let catId = rootGetters['productDetail/common/cat_id']

      let shipInfo = rootGetters['productDetail/ship_info']
      let countryId = state?.address_cookie?.countryId || '226'
      let isCustomization = rootGetters['productDetail/common/is_customization']
      let detailPromotionFloor = rootGetters['productDetail/detail_promotion_floor']
      let productInfo = rootState.productDetail.hotModules.productInfo
      
      return {
        GB_cssRight,
        GB_cssRight_rp,
        langPath,
        lang,
        SiteUID,
        host,
        user,
        fsAbt,
        language,
        goodsSn,
        goodsId,
        skuInfo,
        skuList,
        shipInfo,

        store_info,
        labelList,
        hasDetailStoreSellerInfo,
        businessModel,
        catId,
        countryId,
        isPaidUser,
        sheinClubPromotionInfo,
        mallInfo,
        mallCode,
        isCustomization,
        detailPromotionFloor,
        productInfo
      }
    },
    quick_ship_content(state, getters, rootState, rootGetters){ // N日达 或者 qs的文案
      let { quickShipContent } = rootGetters['productDetail/ship_info'] || {}
      return quickShipContent
    },
    is_jp_quick_ship(state, getters, rootState, rootGetters){ 
      let { quickShipTimeType } = rootGetters['productDetail/ship_info'] || {}
      return ['3', '4'].includes(quickShipTimeType)
    },
    quick_ship_type(state, getters, rootState, rootGetters){ // N日达 或者 qs的文案
      let { quickShipType } = rootGetters['productDetail/ship_info'] || {}
      return quickShipType
    },
    quick_ship_date(state, getters, rootState, rootGetters){ // N日达 或者 qs的文案
      let { quickShipDate } = rootGetters['productDetail/ship_info'] || {}
      return quickShipDate
    },
    shipping_title(state, getters) {
      let { language, shipInfo, mallInfo, mallCode } = getters.common_info || {}
      let title = mallInfo?.mallInfoList?.length && mallInfo?.isShowMall == '1' ? template(mallInfo?.mallInfoList?.find?.(item => item.mall_code == mallCode)?.mall_name || '', language.SHEIN_KEY_PWA_20781) : language.SHEIN_KEY_PWA_16266
      let { address_cache, address_cookie, is_user_update_address, user_address_list } = state || {}
      let detail = ''
      if (isLogin() && !is_user_update_address && user_address_list && user_address_list.length) {
        let { district, city, state } = user_address_list?.find?.(item => item.isDefault == 1) || user_address_list[0] || {}
        detail = [district, city, state, shipInfo?.shipping_countryname].join(' ')
      } else {
        let { districtId, stateId, cityId, countryId } = address_cookie || {}
        detail = [address_cache?.[districtId] || '', address_cache?.[cityId] || '', address_cache?.[stateId] || '', shipInfo?.shipping_countryname || address_cache?.[countryId]].join(' ')
      }
      return {
        title,
        detail
      }
    },

    shipping_content(state, getters) {
      // title 下，邮费、时效、会员信息、延迟信息
      let { fsAbt, skuInfo, skuList, shipInfo, isPaidUser, sheinClubPromotionInfo, detailPromotionFloor } = getters.common_info || {}
      let { quickshipdetailshow, DetailPageQuickShipShow, itemfreeshipping, sheinclubprice, ShippingLogisticsTime } = fsAbt || {}
      let threePsellerdelivery = fsAbt?.['3Psellerdelivery']?.p?.['3Psellerdelivery'] || ''
      let { Quickshipdetailfloorshow } = quickshipdetailshow?.p || {}
      let skuLargeShip = skuInfo?.largeShip || 0
      let isSupportQuickShip = skuInfo ? skuInfo.isSupportQuickShip == 1 : !!skuList.find(item => item.isSupportQuickShip == 1)
      let isQuickShipAbtMatch = DetailPageQuickShipShow?.param == 'DetailShowQuick' && (Quickshipdetailfloorshow === 'quickship' || Number(Quickshipdetailfloorshow) > 0)

      /* icon */
      let ICON_MAP = {
        normal: {
          name: 'sui_icon_freeshipping_flat_24px',
          color: '#198055',
        },
        quick: {
          name: 'sui_icon_qucikship_flat_16px',
          color: '#198055',
        },
        land: {
          name: 'sui_icon_landship_flat_18px',
          color: '#198055',
        },
        sea: {
          name: 'sui_icon_seaship_flat_18px',
          color: '#198055',
        }
      }
      const getIcon = () => {
        if (skuLargeShip) {
          return skuLargeShip == 2 ? ICON_MAP.land : ICON_MAP.sea
        }
        if (isQuickShipAbtMatch && skuList?.find?.(item => item.isSupportQuickShip == 1)) {
          return ICON_MAP.quick
        }
        return ICON_MAP.normal
      }
      let icon = getIcon()
      /* icon */

      /* quick ship 描述 */
      let descQuickShipTimeHtml = ''
      if (
        isSupportQuickShip &&
        !(Quickshipdetailfloorshow === 'quickship' || Number(Quickshipdetailfloorshow) > 0) &&
        DetailPageQuickShipShow?.param == 'DetailShowQuick'
      ) descQuickShipTimeHtml = shipInfo?.quickShipDesc
      /* quick ship 描述 */

      /* 费用相关 */
      const getShipPriceHtml = () => {
        if (shipInfo?.isOutExposedShippingThreshold != 1) return ''
        // qs ⬇️
        if(isQuickShipAbtMatch && shipInfo?.hasQuickShipTime == 1) {
          if (skuInfo) {
            if (skuInfo.isSupportQuickShip == 1) {
              return shipInfo?.shippingContentTipsOfQuickShip
            }
            // 大件物流 ⬇️
            if (skuLargeShip && !descQuickShipTimeHtml) {
              let largeRes = shipInfo?.largeShipInfoList?.find?.(item => item?.type == skuLargeShip)?.tips
              if (largeRes) return largeRes
            }
            return shipInfo?.shippingContentTipsByCountryId
          } else {
            let supportQuickShipSkus = skuList.filter(item => item.isSupportQuickShip == 1)
            if (supportQuickShipSkus.length) {
              if (supportQuickShipSkus.length == skuList.length) {
                return shipInfo?.shippingContentTipsOfQuickShip
              } else {
                let { shippingContentOfPostage } = shipInfo || {}
                return `${shipInfo?.shippingContentTipsOfQuickShip}${shippingContentOfPostage ? `<p>${shippingContentOfPostage}</p>` : ''}`
              }
            } else {
              return shipInfo?.shippingContentTipsByCountryId
            }
          }
        }

        // 大件物流 ⬇️
        if (skuLargeShip && !descQuickShipTimeHtml) {
          let largeRes = shipInfo?.largeShipInfoList?.find?.(item => item?.type == skuLargeShip)?.tips
          if (largeRes) return largeRes
        }

        // 普通物流 ⬇️
        if (shipInfo?.allNotSupportFreeShippingTips) return shipInfo?.allNotSupportFreeShippingTips
        return shipInfo?.shippingContentTipsByCountryId
      }
      let shipPriceHtml = getShipPriceHtml()
      /* 费用相关 */

      /* 时效 */
      let hasDayPercentsAnalyze = null
      const getShipTimeHtml = () => {
        if (shipInfo?.isOutExposedShippingTime != 1) return ''

        let { shippingDayPercentDesc, hasDayPercents, shipping_time_information } = shipInfo || {}
        let percentDes = (shippingDayPercentDesc && ShippingLogisticsTime?.param?.LogisticsTime == 'B') ?
          `<p>${shippingDayPercentDesc}${hasDayPercents == 1 ? `<i aria-hidden='true' class='suiiconfont sui_icon_doubt_15px_2'></i>` : '' }</p>` :
          ''
        if (hasDayPercents) {
          hasDayPercentsAnalyze = {
            id: '1-6-4-59',
            data: {
              days: shipInfo?.shippingDays,
              shipping_methods: shipInfo?.transportType
            }
          }
        }

        if (isQuickShipAbtMatch && shipInfo?.hasQuickShipTime == 1) {
          if (skuInfo) {
            if (skuInfo.isSupportQuickShip == 1) {
              let { ndayDeliveryBusinessDayDesc, shippingTimeInformationOfQuickShip } = shipInfo || {}
              let result = ''
              if (shippingTimeInformationOfQuickShip) {
                result = shippingTimeInformationOfQuickShip
              }
              if (ndayDeliveryBusinessDayDesc) {
                if (result) return `${result}<p>${ndayDeliveryBusinessDayDesc}</p>` 
                return ndayDeliveryBusinessDayDesc
              }
              return result
            } else {
              if (skuLargeShip && !descQuickShipTimeHtml) {
                let largeRes = shipInfo?.largeShipInfoList?.find?.(item => item?.type == skuLargeShip)
                if (largeRes) {
                  let { timeInfo, dayPercentDesc } = largeRes || {}
                  if (timeInfo) {
                    return `${timeInfo}${dayPercentDesc ? `,<p>${dayPercentDesc}</p>` : ''}`
                  }
                }
              }
              if (shipInfo?.quickShipDesc && threePsellerdelivery == 'show' && shipInfo?.isHideForLocalShop == 1) return ''
              return `${shipInfo?.shipping_time_information}${percentDes}`
            }
          } else {
            let supportQuickShipSkus = skuList.filter(item => item.isSupportQuickShip == 1)
            if (supportQuickShipSkus.length) {
              if (supportQuickShipSkus.length == skuList.length) {
                let { ndayDeliveryBusinessDayDesc, shippingTimeInformationOfQuickShip } = shipInfo || {}
                let result = ''
                if (shippingTimeInformationOfQuickShip) {
                  result = shippingTimeInformationOfQuickShip
                }
                if (ndayDeliveryBusinessDayDesc) {
                  if (result) return `${result}<p>${ndayDeliveryBusinessDayDesc}</p>` 
                  return ndayDeliveryBusinessDayDesc
                }
                return result
              } else {
                let { shippingContentOfQuickShip, shippingContentOfNormal, ndayDeliveryBusinessDayDesc } = shipInfo || {}
                let result = ''
                for (let i of [shippingContentOfQuickShip, shippingContentOfNormal, ndayDeliveryBusinessDayDesc]) {
                  if (i) result = result + `<p>${i}</p>` 
                }
                return result
              }
            } else {
              if (shipInfo?.quickShipDesc && threePsellerdelivery == 'show' && shipInfo?.isHideForLocalShop == 1) return ''
              return `${shipInfo?.shipping_time_information}${percentDes}`
            }
          }
        }

        if (skuLargeShip && !descQuickShipTimeHtml) {
          let largeRes = shipInfo?.largeShipInfoList?.find?.(item => item?.type == skuLargeShip)
          if (largeRes) {
            let { timeInfo, dayPercentDesc } = largeRes || {}
            if (timeInfo) {
              return `${timeInfo}${dayPercentDesc ? `,<p>${dayPercentDesc}</p>` : ''}`
            }
          }
        }

        // 普通时效
        if (shipInfo?.quickShipDesc && threePsellerdelivery == 'show' && shipInfo?.isHideForLocalShop == 1) return ''
        return `${shipping_time_information}${percentDes}`
      }
      let shipTimeHtml = getShipTimeHtml()
      /* 时效 */

      /* 延迟提醒 */
      let delayHtml = ['freeremind', 'remind'].includes(itemfreeshipping?.p?.itemfreeshipping) && shipInfo?.suppleDesc
      /* 延迟提醒 */


      /* shipping shein club */
      let sheinClubInfo = (() => {
        if (isPaidUser) return null
        if (sheinclubprice?.p !== 'exposure_prime_price') return null
        if (sheinClubPromotionInfo) {
          return sheinClubPromotionInfo.aggregateMemberResult.shipPaidMemberShowTipsObject
        }
        if (skuInfo) {
          return skuInfo.otherAggregateMemberResult?.shipPaidMemberShowTipsObject
        }
        return detailPromotionFloor?.otherAggregateMemberResult?.shipPaidMemberShowTipsObject
      })()
      let sheinClubText = sheinClubInfo?.paidNumberKey || ''
      let sheinClubAnalysisData = null
      if (sheinClubText) {
        let { primeLevel, totalSaving } = sheinClubPromotionInfo?.aggregateMemberResult || {}
        let labeltype = sheinClubInfo.labelType || ''
        sheinClubAnalysisData = {
          id: '1-6-1-64',
          data: {
            prime_level: primeLevel || '0',
            total_saving: totalSaving || '0.00',
            location: 'shipping',
            from: 2,
            labeltype
          }
        }
      }
      /* shipping shein club */
      return {
        icon,
        descQuickShipTimeHtml,
        shipPriceHtml,
        shipTimeHtml,
        delayHtml,
        sheinClubText,
        sheinClubAnalysisData,
        hasDayPercentsAnalyze
      }
    },


    /* 下方样式统一的项  */
    shipping_cod_policy(state, getters) {
      // 货到付款政策
      let { productInfo } = getters.common_info
      let { cod_title } = productInfo || {}
      if (!cod_title) return null
      let { title, content } = state.cod_policy_drawer_data || {}
      return {
        title: cod_title,
        content,
        drawerTitle: title
      }
    },
    shipping_return_policy(state, getters) {
      let { productInfo } = getters.common_info
      let { returnType, return_title } = productInfo || {}
      if (!return_title) return null
      let { title, content } = state.return_policy_drawer_data || {}
      if (content) content = content.replace?.(/href=\"\//, `href=\"${gbCommonInfo.langPath}\/`)
      return {
        title: return_title,
        isReturn: returnType == 1,
        content,
        drawerTitle: title
      }
    },
    shipping_guarantee(state, getters) {
      // 购物保障
      const { fsAbt, language } = getters.common_info || {}
      const showShippingGuarantee = fsAbt?.Guarantee?.p?.Guarantee === 'show'
      if (!showShippingGuarantee) return null
      const { SHEIN_KEY_PWA_27597, SHEIN_KEY_PWA_27580, SHEIN_KEY_PWA_27410, SHEIN_KEY_PWA_27411, SHEIN_KEY_PWA_27412 } = language
      return {
        title: SHEIN_KEY_PWA_27597,
        content: [ SHEIN_KEY_PWA_27580, SHEIN_KEY_PWA_27410, SHEIN_KEY_PWA_27411, SHEIN_KEY_PWA_27412 ],
        language
      }
    },
    shipping_seller(state, getters, rootState, rootGetters) {
      let { detail_store_seller_info } = state || {}

      const { fsAbt, language, skuInfo, skuList, SiteUID, hasDetailStoreSellerInfo, goodsId, goodsSn, GB_cssRight_rp, langPath, businessModel, store_info } = getters.common_info || {}

      let { detailshowsellerinfo, localwarehouse, moresellerinfo, obligationsseller, ReportItem } = fsAbt || {}

      if (detailshowsellerinfo?.param?.detailshowsellerinfo != 'show') return null

      let sellerTitle = rootGetters['productDetail/common/seller_info_tips'] || ''
      if (!sellerTitle) return null

      let localWarehouseText = (() => {
        if (localwarehouse?.param?.localwarehouse != 'show') return ''
        if (skuInfo ? skuInfo.isSupportQuickShip != 1 : !skuList.find(item => item.isSupportQuickShip == 1)) return ''
        let isEur = ['pwfr', 'pwuk', 'pwde', 'pwes', 'pwit', 'pwnl', 'mse', 'meur', 'mpl', 'mpt', 'mch', 'mroe', 'meuqs'].includes(SiteUID)
        let isUs = !!~SiteUID.indexOf('us')
        let isUk = !!~SiteUID.indexOf('uk')
        if (isUs) return language.SHEIN_KEY_PWA_31569
        if (isUk) return language.SHEIN_KEY_PWA_31571
        if (isEur) return language.SHEIN_KEY_PWA_31569
        return ''
      })()

      let showSellerIcon = hasDetailStoreSellerInfo && moresellerinfo?.p?.moresellerinfo === 'show'
      let sellerConnectionInfo = (() => {
        if (!showSellerIcon) return null
        if (!detail_store_seller_info?.storeMerchantFieldValues?.length) return null
        return detail_store_seller_info.storeMerchantFieldValues.map(item => {
          let { display_name: name, field_value: value } = item
          return {
            name, value
          }
        })
      })()

      let soldBuyTips = (() => {
        let { descriptionOne, descriptionTwo } = detail_store_seller_info?.sellerInfoDescription || {}
        if (!descriptionOne && !descriptionTwo) return null
        return [descriptionOne, descriptionTwo].map((item, index) => ({ value: item, key: 'soldBuyTips' + index }))
      })()

      let showReport = false
      let { Reportshow, limitstore } = ReportItem?.p || {}
      if (Reportshow === 'show') {
        showReport = limitstore === 'no' || (limitstore === 'yes' && store_info.storeType == 1)
      }

      let reportData = {
        language,
        goodsSn,
        hasCall: ReportItem?.p?.call === 'yes',
        storeStoreType: store_info.storeStoreType,
        storeCode: store_info.store_code,
        businessModel,
        isDsaSite: ['meur', 'meuqs', 'pwfr', 'pwde', 'pwit', 'mes', 'pwnl', 'mpl', 'mpt', 'mroe', 'pwes', 'mse', 'pwuk', 'mat', 'mro'].includes(SiteUID),
      }

      return {
        sellerTitle,
        localWarehouseText,
        showSellerIcon,
        sellerAnalysisData: { goods_id: goodsId, store_code: store_info.store_code },
        sellerConnectionInfo,
        soldBuyTips,
        language,
        goodsId,
        showObligationsseller: obligationsseller?.p?.obligationsseller === 'show',
        GB_cssRight_rp,
        showReport,
        langPath,
        isUs: !!~SiteUID.indexOf('us'),
        reportData,
      }
    },
    free_shipping_info(state, getters) {
      return getters.common_info.shipInfo.freeShippingInfo || {}
    }
  },
}
